import {Grid} from "semantic-ui-react";
import FieldName from "../Shared/FieldName";
import FieldValue from "../Shared/FieldValue";
import React from "react";

function DictionaryItemsHeader(props: { visible: boolean }) {
    if (!props.visible) {
        return <></>
    }
    return <Grid.Row style={{paddingBottom: "0px"}}>
        <FieldName>
            Ключ
        </FieldName>
        <FieldValue>
            Значение
        </FieldValue>
    </Grid.Row>;
}

export default DictionaryItemsHeader;