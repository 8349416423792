import {InformationPanelItem} from "../Dashboard/InformationPanelItem";
import {List} from "semantic-ui-react";
import React from "react";
import {observer} from "mobx-react-lite";
import storage from "../../Stores/UnloadsSettingsStorage";
import UnloadRaw from "./UnloadRaw";
import NewUnload from "./NewUnload";

function UnloadList() {
    return <InformationPanelItem header={'Файлы выгрузки'} listPlaceholder loading={storage.loading && !storage.isLoaded}>
        {storage.unloads.length > 0 && <List selection>
            {storage.unloads.map(unload => <UnloadRaw key={unload.id} unload={unload}/>)}
        </List>}
        <NewUnload/>
    </InformationPanelItem>;
}

export default observer(UnloadList);
