import React from 'react';
import {Button, GridRow} from "semantic-ui-react";
import storage from "../../Stores/AvitoAccountsSettingsStorage";
import {observer} from "mobx-react-lite";

function NewAvitoAccount() {
    return <GridRow>
        <Button fluid color={'teal'}
                onClick={() => storage.startCreatingAccount()}
                content={'Добавить'}
                disabled={storage.hasNew || (storage.loading && !storage.isLoaded)}
        />
    </GridRow>
}

export default observer(NewAvitoAccount);
