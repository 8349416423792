import { observer } from 'mobx-react-lite'
import React from 'react'
import { Header, Icon, Segment, TransitionablePortal } from 'semantic-ui-react';
import successStore from "../Stores/SuccessStore";

function SuccessPortal() {
    return (
        <TransitionablePortal onClose={successStore.close} open={successStore.isOpen}>
            <Segment color='green'
                style={{ left: '83%', position: 'fixed', top: '0%', zIndex: 1000 }}
            >
                <Header>Успех!<Icon onClick={successStore.close} name='close' size='small' fitted style={{ 'float': 'right', 'fontSize': '0.8em', 'cursor': 'pointer' }} /></Header>
                {successStore.messages.map((message, index) => <p key={index}>{message}</p>)}
            </Segment>
        </TransitionablePortal>
    )
}

export default observer(SuccessPortal);