import React, {useEffect} from "react";
import UserTable from "./UserTable";
import {observer} from "mobx-react-lite";
import usersStore from "../UsersStore";

function UsersPage() {
    useEffect(() => {
        void usersStore.load();
    }, []);
    return <UserTable users={usersStore.data} />;
}

export default observer(UsersPage);
