import {observable} from "mobx";
import DictionaryItem from "./DictionaryItem";

export class Dictionary {
    id: string;
    name: string;
    @observable items: DictionaryItem[];

    constructor(id: string, name: string, items: DictionaryItem[]) {
        this.id = id;
        this.name = name;
        this.items = items;
    }

    addItem() {

    }

    removeItem(item: DictionaryItem) {

    }
}