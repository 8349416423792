import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {Grid} from "semantic-ui-react";
import DictionaryList from "./DictionaryList";
import dictionariesStore from "../../Stores/Dictioanaries/DictionariesStore";
import {Route, Switch} from "react-router-dom";
import DictionaryPage from "./DictionaryPage";
import DeleteDictionaryButton from "./DeleteDictionaryButton";
import dictionaryStore from "../../Stores/Dictioanaries/DictionaryStore";

function DictionariesPage() {
    useEffect(() => {
        void dictionariesStore.load();
        
        return function cleanup() {
            dictionariesStore.cleanup();
        }
    }, []);

    return <Grid>
        <DeleteDictionaryButton />
        <Grid.Column width={3}>
            <DictionaryList/>
        </Grid.Column>
        <Grid.Column width={10}>
            <Switch>
                <Route path={`/dictionaries/:id`}>
                    <DictionaryPage/>
                </Route>
            </Switch>
        </Grid.Column>
    </Grid>
}

export default observer(DictionariesPage);