import React from "react";
import {Button, Form, Grid, Segment} from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import authStore from "../../Stores/AuthStore";

function AdminLoginPage() {
    return (
        <Grid centered columns={3} stackable style={{paddingTop: '25px'}}>
            <Grid.Column>
                <Segment>
                    <Form onSubmit={() => authStore.authByAdmin()}>
                        <Form.Input label={'Логин'} value={authStore.login || ""} icon={'user'} iconPosition='left'
                                    onChange={e => authStore.login = e.target.value}/>
                        <Form.Input type={'password'} label={'Пароль'} value={authStore.password || ""} icon={'lock'}
                                    iconPosition='left'
                                    onChange={e => authStore.password = e.target.value}/>
                        <Button type='submit' primary fluid>Войти</Button>
                    </Form>
                </Segment>
            </Grid.Column>
        </Grid>
    )
}

export default observer(AdminLoginPage);