import {observable} from "mobx";
import FieldEditorStore from "../Stores/FieldEditorStore";
import unloadsSettingsStorage from "../Stores/UnloadsSettingsStorage";

export default class UnloadField {
    id: string;
    @observable name: string;
    editor: FieldEditorStore;

    constructor(id: string, name: string) {
        this.id = id;
        this.name = name;
        this.editor = new FieldEditorStore('settings/unloadField.edit', () => unloadsSettingsStorage.load(), this.name, this.id);
    }
}
