import React, {useLayoutEffect, useRef} from "react";
import {observer} from "mobx-react-lite";
import {Button, Form, Comment, Header} from "semantic-ui-react";
import ChatStorage from "../../Stores/Messenger/ChatStorage";
import ChatMessageComponent from "./ChatMessageComponent";
import {AvitoFullMessageDto, AvitoUserDto} from "../../Entities/Dtos";

const AvitoSystemUser: AvitoUserDto = {
    id: 1,
    name: 'System',
    avatarUrl: 'https://static.avito.ru/stub_avatars/S/11_256x256.png',
    profileUrl: 'https://www.avito.ru'
};

const NewAvitoSystemUser: AvitoUserDto = {
    id: 0,
    name: 'System',
    avatarUrl: 'https://static.avito.ru/stub_avatars/S/11_256x256.png',
    profileUrl: 'https://www.avito.ru'
};

function ChatComponent(props: { chat: ChatStorage, accountUser: AvitoUserDto, messagesDivHeight?: string }) {
    const {chat, accountUser, messagesDivHeight} = props;
    const ref = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        scrollToBottom();
    });
    
    function scrollToBottom(){
        if (ref && ref.current) {
            ref.current.scrollTop = ref.current.scrollHeight;
        }
    }
    function getAuthor(message: AvitoFullMessageDto): AvitoUserDto {
        const authorId = message.authorId;
        if (authorId === AvitoSystemUser.id) {
            return AvitoSystemUser;
        }
        if (authorId === NewAvitoSystemUser.id) {
            return NewAvitoSystemUser;
        }
        if (authorId === accountUser.id) {
            return accountUser;
        }

        return chat.client;
    }

    return <>
        <Comment.Group>
            <Header as='h3' dividing>
                <a style={{color: 'black'}} href={chat.ad.url} target={'_blank'}>{chat.ad.name}</a>
            </Header>
            <div ref={ref} style={{maxHeight: messagesDivHeight ? messagesDivHeight : '500px', overflowY: 'auto'}}>
                {chat.messages.map(x => <ChatMessageComponent message={x}
                                                              imageOnLoad={scrollToBottom}
                                                              key={x.id}
                                                              hasActions={false}
                                                              author={getAuthor(x)}/>)}
            </div>
            <Form reply loading={chat.isMessageSending} onSubmit={() => chat.sendMessage()}>
                <Form.TextArea value={chat.messageText}
                               onKeyDown={(e: any) => {
                                   if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
                                       void chat.sendMessage();
                                   }
                               }}
                               onChange={(_, data) => chat.setMessageText(data.value as string)}/>
                <Button content='Отправить' labelPosition='left' icon='edit' primary/>
            </Form>
        </Comment.Group>
    </>
}

export default observer(ChatComponent);