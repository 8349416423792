import {observable, runInAction} from "mobx";
import {ProductFolderDto} from "../Entities/Dtos";
import api from "../Services/Api";
import {ProductFolder} from "../Entities/ProductFolder";

class ProductFolderListStore {
    @observable folders: ProductFolder[] = []
    async load() {
        const result = await api.get('productFolder/list');
        if (result.success) {
            runInAction(() => {
                this.folders = (result.data.productFolders as ProductFolderDto[]).map((x) => new ProductFolder(x.id, x.name, x.templateName));
            })
        }
    }
}

const productFolderListStore = new ProductFolderListStore();
export default productFolderListStore;