import {observable} from "mobx";

import api from "../Services/Api";
import {Order} from "../Entities/Order/Order";


class OrderListStore {
    @observable orders: Order[] = [];
    @observable loading: boolean = false;
    @observable error: string | null = null;
    @observable page: number = 0;
    @observable totalCount: number = 0;
    @observable dateFrom?: Date;
    @observable dateTo?: Date;
    itemsPerPage: number = 30;
    
    get pagesCount(): number {
        return Math.ceil(this.totalCount / this.itemsPerPage);
    }

    public async init() {
        this.page = 0;
        this.dateFrom = undefined;
        this.dateTo = undefined;
        await this.load();
    }
    
    private async load(){
        this.loading = true;
        
        try {
            const result = await api.post("order/list", {
                page: this.page,
                itemsPerPage: this.itemsPerPage,
                dateFrom: this.dateFrom?.toISOString(),
                dateTo: this.dateTo?.toISOString(),
            });
            if(result.success) {
                this.orders = result.data.orders;
                this.totalCount = result.data.totalCount;
            }
        }
        finally {
            this.loading = false;
        }
    }

    public search() {
        this.page = 0;
        void this.load();
    }
    
    public setPage(page: number) {
        this.page = page;
        void this.load();
    }

    public setDateFrom(date? : Date) {
        this.dateFrom = date;
        this.search();
    }

    public setDateTo(date? : Date) {
        this.dateTo = date;
        this.search();
    }
}

const orderListStore = new OrderListStore();

export default orderListStore;