import {observable} from "mobx";
import FieldEditorStore from "../Stores/FieldEditorStore";
import IHasNameAndEditor from "./IHasNameAndEditor";
import storesSettingsStorage from "../Stores/StoresSettingsStorage";

export default class StoreField implements IHasNameAndEditor {
    id: string;
    @observable name: string;
    editor: FieldEditorStore;

    constructor(id: string, name: string) {
        this.id = id;
        this.name = name;
        this.editor = new FieldEditorStore('settings/storeField.edit', () => storesSettingsStorage.load(), this.name, this.id);
    }
}
