import {UserDto} from "../Entities/Dtos";
import api from "../Services/Api";
import {observable} from "mobx";
import authStore from "../Stores/AuthStore";

class UsersStore {
    @observable data: UserDto[] = [];
    @observable userAddingIsStarted: boolean = false;
    @observable newUserToken: string = '';
    @observable userCreatingInProcess: boolean = false;

    async load() {
        const result = await api.get('admin/users');
        if (result.success) {
            this.data = result.data.users;
        }
    }

    async runUsersDataUpdate() {
        await api.post('admin/runUsersDataUpdate', {});
    }

    async generateToken(userId: string) {
        const result = await api.post('admin/generateUserToken', {
            userId: userId
        });

        if (result.success) {
            authStore.logIn(result.data);
            window.open('/');
        }
    }

    startAddingUser() {
        this.userAddingIsStarted = true;
    }

    stopAddingUser() {
        this.userAddingIsStarted = false;
        this.newUserToken = '';
    }

    changeNewUserToken(value: string) {
        if (this.userCreatingInProcess) return;
        this.newUserToken = value;
    }

    async addUser() {
        if (this.userCreatingInProcess) return;

        this.userCreatingInProcess = true;
        const result = await api.post('admin/user.create', {token: this.newUserToken});
        if (result.success) {
            this.stopAddingUser();
            await this.load();
        } else {
            alert(result.error);
        }
        this.userCreatingInProcess = false;
    }
}

const usersStore = new UsersStore();
export default usersStore;
