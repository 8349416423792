import React from "react";
import dictionaryStore from "../../Stores/Dictioanaries/DictionaryStore";
import {Button, Grid, Input} from "semantic-ui-react";
import FieldName from "../Shared/FieldName";
import FieldValue from "../Shared/FieldValue";
import EditorInput from "../Shared/EditorInput";
import {observer} from "mobx-react-lite";

function CreateDictionaryItem() {
    const creationItem = dictionaryStore.creationDictionaryItem;
    if (creationItem) {
        return <Grid.Row>
            <FieldName>
                <Input
                    autoFocus
                    value={creationItem.key}
                    onChange={e => creationItem.updateKey(e.target.value)}
                    fluid
                />
            </FieldName>
            <FieldValue>
                <EditorInput
                    value={creationItem.value}
                    onEdit={value => creationItem.updateValue(value)}
                    cancel={() => dictionaryStore.stopCreationDictionaryItem()}
                    save={() => creationItem.save()}
                    canSave={creationItem.canSave}
                    isSaving={creationItem.isSaving}
                    ignoreOnBlur
                    disableAutoFocus
                />
            </FieldValue>
        </Grid.Row>
    }
    
    return <Grid.Row>
        <Grid.Column width={5}>
            <Button
                onClick={() => dictionaryStore.startCreationDictionaryItem()}
                fluid
                content='Добавить'
                labelPosition={'right'}
                basic icon={'add'}/>
        </Grid.Column>
    </Grid.Row>
}

export default observer(CreateDictionaryItem);