import React from 'react';
import {Message, Icon} from 'semantic-ui-react';

const WelcomeMessage = () => {
    return (
        <Message icon={'info'}
                 header={'Добро пожаловать!'}
                 content={
                     <>
                         <p>
                             Для успешного использования продукта ознакомьтесь с
                             прилагаемой <a href={'https://wiki.unitxml.ru/ru/avito/start'}>документацией</a>.
                             <br></br>
                             Это поможет вам максимально эффективно использовать все возможности.
                         </p>
                         <p>
                             В случае затруднений при настройке интеграции, обращайтесь в <a href={'https://help.unitxml.ru/help/844394147'}>техническую поддержку</a>.
                             <br></br>
                             Наша команда экспертов готова помочь вам разрешить любые вопросы.
                         </p>
                         <p>
                             Также, для оперативного общения и решения текущих задач, вы можете
                             воспользоваться <a href={'https://t.me/+lhWAe4Xe0RJhOTAy'}>Telegram</a>.
                         </p>
                         <p>
                             Мы предоставляем бесплатную первичную настройку
                             интеграции, стремясь сделать процесс максимально комфортным и
                             беззаботным для вас.
                         </p>
                         <p>Спасибо за выбор нашей компании <Icon color='red' name='heart'/></p>
                         <p>С Уважением, команда <b>UnitXML</b></p>
                     </>
                 }>
        </Message>
    );
};

export default WelcomeMessage;
