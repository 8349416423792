import {Message, MessageContent, MessageHeader} from "semantic-ui-react";
import {Link} from "react-router-dom";
import React from "react";

function StoresNotActiveWarning() {
    return<Message warning>
        <MessageHeader>Внимание</MessageHeader>
        <MessageContent>
            <p>Данный функционал активен только с включенной опцией</p>
            <Link to={'/home'}>Проверять остатки на складах</Link>
        </MessageContent>
    </Message>
}

export default StoresNotActiveWarning;
