import {observer} from 'mobx-react-lite';
import React from 'react';
import {Dropdown, Grid, Header} from 'semantic-ui-react';
import CreateFeedButton from '../Profile/SkladSettings/CreateFeedButton/CreateFeedButton';
import skladStore from "../../Stores/SkladStore";
function Cron() {
    return (
        <>
            <Header as='h5' textAlign='center'>
                Периодичность выгрузки
            </Header>
            <Grid centered style={{marginBottom: '15px'}}>
                <Grid.Row verticalAlign={'middle'}>
                    <div>Выгружать файлы <Dropdown
                        className='shadow'
                        selection
                        name='default'
                        options={skladStore.intervals.map((f) => {
                            return {text: f.name, value: f.id};
                        })}
                        value={skladStore.intervalId || ''}
                        onChange={(_, data) => {
                            skladStore.intervalId = data.value ? (data.value as string) : undefined;
                            skladStore.hasChanges = true;
                        }
                        }
                        placeholder='Выберите интервал выгрузки'
                        style={{marginLeft: '5px', marginRight: '5px'}}
                    />
                        {skladStore.selectedInterval && skladStore.selectedInterval.showTimePicker && <>в <input
                            style={{marginLeft: '5px'}}
                            type="time" 
                            value={skladStore.period}
                            onChange={(e) => {
                                skladStore.period = e.target.value;
                                skladStore.hasChanges = true;
                            }}/>
                            &nbsp; по МСК &nbsp;
                        </>
                        }
                    </div>

                </Grid.Row>
                <Grid.Row verticalAlign={'middle'} style={{paddingTop: '0px'}}>
                    <CreateFeedButton/>
                </Grid.Row>
            </Grid>
        </>
    );
}

export default observer(Cron);