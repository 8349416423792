import {Checkbox, Grid, Input} from "semantic-ui-react";
import React from "react";
import FieldName from "../Shared/FieldName";

function PriorityField(props: {
    priority: number,
    onChange: (value: number) => void,
    ignoreLowerStores: boolean,
    onChangeIgnoreLowerStores: (value: boolean) => void
}) {
    const {priority, onChange, ignoreLowerStores, onChangeIgnoreLowerStores} = props;
    return <Grid.Row>
        <FieldName name={'Приоритет'}/>
        <Grid.Column width={5}>
            <Input type={'number'}
                   fluid
                   value={priority}
                   onChange={(e) => onChange(Number(e.target.value ?? 0))}
            />
        </Grid.Column>
        <Grid.Column verticalAlign={'middle'} width={6}>
            <Checkbox checked={ignoreLowerStores}
                      onChange={(_, data) => onChangeIgnoreLowerStores(data.checked!)}
                      label={'Игнорировать склады ниже приоритетом'}/>
        </Grid.Column>
    </Grid.Row>
}

export default PriorityField;
