import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {
    Button,
    Grid, Icon, Input,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHeader,
    TableHeaderCell,
    TableRow
} from "semantic-ui-react";
import { NavLink } from "react-router-dom";

import orderListStore from "../../Stores/OrderListStore";
import {InformationPanelItem} from "../Dashboard/InformationPanelItem";
import orderStatusConverter from "../../Services/OrderStatusConverter";
import utils from "../../Services/Utils";

function formatDateForInput(date?: Date) {
    if (!date) {
        return "";
    }

    return date.getFullYear() + "-" + ("0"+(date.getMonth()+1)).slice(-2) + "-" +("0" + date.getDate()).slice(-2);
}

const TableRows = observer(() => {
    return  <TableBody>
        {orderListStore.orders.map((order) => (
            <TableRow key={order.id}>
                <TableCell>
                    <NavLink to={`/orders/${order.id}`}>{order.marketplaceId}</NavLink>
                </TableCell>
                <TableCell>{utils.getLocalizedDateString(order.createdOn)}</TableCell>
                <TableCell>{order.avitoAccount.name}</TableCell>
                <TableCell><a href={order.chat.client.profileUrl}>{order.chat.client.name}</a></TableCell>
                <TableCell>{orderStatusConverter.getStatusName(order.avitoStatus)}</TableCell>
                <TableCell>{order.prices.price}</TableCell>
            </TableRow>
        ))}
    </TableBody>
});

const Filters = observer(() => {
    return  <Grid>
        <Grid.Row>
            <Grid.Column width={12} style={{display: 'flex'}}>
                <Input
                    label='Дата создания c'
                    labelPosition='left'
                    size={"small"}
                    type='date'
                    value={formatDateForInput(orderListStore.dateFrom)}
                    onChange={(_, data) => {
                        if(data.value) {
                            orderListStore.setDateFrom(new Date(data.value));
                        } else {
                            orderListStore.setDateFrom(undefined);
                        }
                    }}
                    icon={<Icon name='delete' link onClick={() => orderListStore.setDateFrom(undefined)}/>}
                />
                <Input
                    size={"small"}
                    label='по'
                    labelPosition='left'
                    type='date'
                    icon={<Icon name='delete' link onClick={() => orderListStore.setDateTo(undefined)}/>}
                    value={formatDateForInput(orderListStore.dateTo)}
                    onChange={(_, data) => {
                        if(data.value) {
                            orderListStore.setDateTo(new Date(data.value));
                        } else {
                            orderListStore.setDateTo(undefined);
                        }
                    }}
                    style={{marginLeft: '2px'}}
                />
                <Button
                    color={'teal'}
                    labelPosition='right'
                    style={{marginLeft: '10px'}}
                    disabled={orderListStore.loading}
                    icon={orderListStore.loading ? 'spinner' : 'right arrow'}
                    content={'Найти'}
                    onClick={() => orderListStore.search()}
                />
            </Grid.Column>
        </Grid.Row>
    </Grid>
});

function OrdersPage() {
    useEffect(() => {
        void orderListStore.init();
    }, [])

    return <Grid >
        <Grid.Row>
            <Grid.Column width={12}>
                <InformationPanelItem header={'Заказы'}>
                    <Filters />
                </InformationPanelItem>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column width={12}>
                <Table celled compact>
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell>Номер</TableHeaderCell>
                            <TableHeaderCell>Дата создания</TableHeaderCell>
                            <TableHeaderCell>Магазин</TableHeaderCell>
                            <TableHeaderCell>Покупатель</TableHeaderCell>
                            <TableHeaderCell>Статус</TableHeaderCell>
                            <TableHeaderCell>Сумма</TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableRows />
                    <TableFooter>
                        <TableRow textAlign={'right'}>
                            <TableHeaderCell colSpan={6}>
                                <Pagination
                                    disabled={orderListStore.loading}
                                    totalPages={orderListStore.pagesCount}
                                    onPageChange={(_, pageData)=>orderListStore.setPage(pageData.activePage as number - 1)}
                                />
                            </TableHeaderCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </Grid.Column>
        </Grid.Row>
    </Grid>
}

export default observer(OrdersPage);