import skladStore from "../../../../Stores/SkladStore";
import CheckboxWithTip from "../../../Dashboard/CheckboxWithTip";
import React from "react";
import {observer} from "mobx-react-lite";

function StoreStockFilters() {
    if (skladStore.fastStockCheck) {
        return <>
            <CheckboxWithTip
                label='Доступно'
                tip={'Eсли включена данная опция, то приложение будет прибавлять к остаткам товара на всех выбранных складах товар в ожидании и резерве, и если при этом будет положительный остаток, то товар попадет в выгрузку'}
                checked={skladStore.checkQuantity || (!skladStore.checkRealStock && !skladStore.checkFreeStock)}
                disabled={skladStore.checkFreeStock || skladStore.checkRealStock}
                onChange={(checked) => {
                    skladStore.checkQuantity = checked;
                    skladStore.hasChanges = true;
                }}
            />
            <CheckboxWithTip
                label='Остаток за вычетом резерва'
                tip={'Eсли включена данная опция, то приложение будет вычитать из остатков товара на всех выбранных складах товар в резерве, и если при этом будет положительный остаток, то товар попадет в выгрузку'}
                checked={skladStore.checkFreeStock}
                disabled={skladStore.checkRealStock || skladStore.checkQuantity}
                onChange={(checked) => {
                    skladStore.checkFreeStock = checked;
                    skladStore.hasChanges = true;
                }}
            />
            <CheckboxWithTip
                label='Физический остаток'
                tip={'Eсли включена данная опция, то в выгрузку попадут товары с положительным физическим остатком на складах, без учёта резерва и ожидания'}
                checked={skladStore.checkRealStock}
                disabled={skladStore.checkFreeStock || skladStore.checkQuantity}
                onChange={(checked) => {
                    skladStore.checkRealStock = checked;
                    skladStore.hasChanges = true;
                }}
            />
        </>;
    }

    return <>
        <CheckboxWithTip
            label='Вычитать из остатков Резерв'
            tip={'Eсли включена данная опция, то приложение будет вычитать из остатков товара зарезервированный товар, и если при этом будет положительный остаток, то товар попадет в выгрузку'}
            checked={skladStore.removeReserve}
            onChange={(checked) => {
                skladStore.removeReserve = checked;
                skladStore.hasChanges = true;
            }}
        />
        <CheckboxWithTip
            label='Вычитать из остатков товары в Ожидании'
            tip={'Eсли включена данная опция, то приложение будет вычитать из остатков товара на всех выбранных складах товар в ожидании, и если при этом будет положительный остаток, то товар попадет в выгрузку'}
            checked={skladStore.removeTransit}
            disabled={skladStore.addTransit}
            onChange={(checked) => {
                skladStore.removeTransit = checked;
                skladStore.hasChanges = true;
            }}
        />
        <CheckboxWithTip
            label='Прибавлять к остаткам товары в Ожидании'
            tip={'Eсли включена данная опция, то приложение будет прибавлять к остаткам товара на всех выбранных складах товар в ожидании, и если при этом будет положительный остаток, то товар попадет в выгрузку'}
            checked={skladStore.addTransit}
            disabled={skladStore.removeTransit}
            onChange={(checked) => {
                skladStore.addTransit = checked;
                skladStore.hasChanges = true;
            }}
        />
    </>;
}

export default observer(StoreStockFilters);