import React, {useEffect} from 'react';
import {Button, Icon, Input, List} from 'semantic-ui-react';
import {observer} from 'mobx-react-lite';
import {InformationPanelItem} from '../Dashboard/InformationPanelItem';
import skladStore from "../../Stores/SkladStore";
import templateListStore from "../../Stores/TemplateListStore";
import './Style.css'

function TemplateList() {
    useEffect(() => {
        if (!templateListStore.isLoaded) {
            void templateListStore.load();
        }
        if (!skladStore.isLoaded) {
            void skladStore.load();
        }
    }, []);
    return (
        <>
            <InformationPanelItem header='Шаблоны' smallTopPadding>

                <Button
                    loading={templateListStore.isLoading}
                    fluid color='teal'
                    onClick={() => templateListStore.create()}
                    disabled={templateListStore.isLoading || templateListStore.hasNew}
                >
                    Новый шаблон
                </Button>
                {templateListStore.data.length > 0 &&
                    <>
                        <Input style={{marginTop: '1em'}} value={templateListStore.searchText}
                               onChange={(e) => templateListStore.changeSearchText(e.target.value)} 
                               fluid 
                               icon='search'
                               autoFocus={templateListStore.searchInStarted}
                               onBlur={() => templateListStore.stopSearch()}
                               size={'small'} placeholder='Поиск...'/>
                        <List selection divided verticalAlign='middle'>
                            {templateListStore.data.filter(x=>!templateListStore.searchText || x.name.toLowerCase().includes(templateListStore.searchText.toLowerCase())).map((template) => {
                                return (
                                    <List.Item
                                        key={template.id}
                                        name='inbox'
                                        active={template === templateListStore.selected}
                                        onClick={() => templateListStore.select(template)}
                                        style={{overflowWrap: 'anywhere'}}
                                        draggable
                                        onDragStart={() => templateListStore.startSwapping(template)}
                                        onDragEnter={() => templateListStore.finishSwapping(template)}
                                        onDragEnd={() => templateListStore.swap()}
                                        onDragOver={(e: any) => e.preventDefault()}
                                    >
                                        <List.Content floated='right'>
                                            <Icon name='close' onClick={(e: any) => {
                                                e.preventDefault();
                                                void template.delete();
                                            }} className={'delete-button'}/>
                                        </List.Content>
                                        {template.name}
                                    </List.Item>
                                )
                            })}
                        </List>
                    </>
                }
            </InformationPanelItem>
        </>
    );
}

export default observer(TemplateList);