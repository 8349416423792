import React from "react";
import {observer} from "mobx-react-lite";
import MessengerAccount from "../../Stores/Messenger/MessengerAccount";
import ChatList from "./ChatList";
import {Grid} from "semantic-ui-react";
import ChatComponent from "./ChatComponent";

function MessengerAccountComponent(props: { account: MessengerAccount }) {
    const {account} = props;
    return <Grid>
        <Grid.Column width={5}>
            <ChatList account={account}/>
        </Grid.Column>
        <Grid.Column width={10}>
            {account.selectedChat && <ChatComponent chat={account.selectedChat} accountUser={account.user}/>}
        </Grid.Column>
    </Grid>
}

export default observer(MessengerAccountComponent);