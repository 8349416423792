import React from "react";
import {observer} from "mobx-react-lite";
import DictionaryRaw from "./DictionaryRaw";
import {InformationPanelItem} from "../Dashboard/InformationPanelItem";
import {Input, List} from "semantic-ui-react";
import CreateDictionary from "./CreateDictionary";
import dictionariesStore from "../../Stores/Dictioanaries/DictionariesStore";

function DictionaryList() {
    return <InformationPanelItem header={'Словари'}
                                 listPlaceholder
                                 hideContentWhenLoading
                                 loading={dictionariesStore.loading && !dictionariesStore.isLoaded}>
        {dictionariesStore.dictionaries.length > 0 &&
            <>
                <Input value={dictionariesStore.searchText}
                       onChange={(e) => dictionariesStore.changeSearchText(e.target.value)}
                       fluid
                       icon='search'
                       autoFocus={dictionariesStore.searchIsStarting}
                       size={'small'} placeholder='Поиск...'/>
                <List selection>
                    {dictionariesStore.dictionaries
                        .filter(x => dictionariesStore.isFitsFilter(x))
                        .map(x =>
                            <DictionaryRaw key={x.id} dictionary={x}/>)}
                </List>
            </>
        }
        <CreateDictionary/>
    </InformationPanelItem>
}

export default observer(DictionaryList);