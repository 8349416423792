import React from "react";
import {Comment, Image} from "semantic-ui-react";
import {AvitoFullMessageDto, AvitoUserDto} from "../../Entities/Dtos";
import utils from "../../Services/Utils";

function ChatMessageComponent(props: { message: AvitoFullMessageDto, author: AvitoUserDto, hasActions: boolean, imageOnLoad: () => void }) {
    const {message, author, hasActions, imageOnLoad} = props;

    return <>
        <Comment>
            <Comment.Avatar src={author.avatarUrl}/>
            <Comment.Content>
                <Comment.Author as='a' href={author.profileUrl} target="_blank"
                                rel="noopener noreferrer">{author.name}</Comment.Author>
                <Comment.Metadata>
                    <div>{utils.createShortStr(message.createdOn)}</div>
                </Comment.Metadata>
                <Comment.Text>
                    <MessageContent message={message} imageOnLoad={imageOnLoad}/>
                </Comment.Text>
                {hasActions && <Comment.Actions>
                    <Comment.Action>Ответить</Comment.Action>
                    <Comment.Action>Удалить</Comment.Action>
                </Comment.Actions>}
            </Comment.Content>
        </Comment>
    </>
}

function MessageContent(props: { message: AvitoFullMessageDto, imageOnLoad: () => void }) {
    const {message, imageOnLoad} = props;
    if (message.image) {
        return <Image rounded src={message.image.tinyImageUrl}
                      href={message.image.fullImageUrl}
                      onLoad={imageOnLoad}
                      target='_blank' size='small'/>;
    }

    if (message.link) {
        return <a href={message.link.url} target={'_blank'}>{message.link.text}</a>
    }

    return <>{message.text}</>;
}

export default ChatMessageComponent;