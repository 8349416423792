import {Icon, List} from "semantic-ui-react";
import React from "react";

function DeleteButton(props: { onDelete: () => void }) {
    const {onDelete} = props;
    return <List.Content floated='right'>
        <Icon name='close'
              onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onDelete();
              }}
              className={'delete-button'}/>
    </List.Content>
}

export default DeleteButton;
