class Utils {
    beautifyJson(input: string): string {
        return JSON.stringify(JSON.parse(input), null, 2)
    }

    getLocalizedDateString(dateStr: string) {
        const date = Date.parse(dateStr);

        return new Intl.DateTimeFormat("ru-RU", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric"
        }).format(date);
    }

    getLocalizedOnlyDateString(dateStr: string) {
        const date = Date.parse(dateStr);

        return new Intl.DateTimeFormat("ru-RU", {
            year: "numeric",
            month: "numeric",
            day: "numeric"
        }).format(date);
    }

    createShortStr(inputDate: Date) {
        const date = new Date(inputDate);
        const now = new Date();

        const isSameDay =
            date.getDate() === now.getDate() &&
            date.getMonth() === now.getMonth() &&
            date.getFullYear() === now.getFullYear();

        const isSameYear = date.getFullYear() === now.getFullYear();

        if (isSameDay) {
            // Если совпадает день, выводим только время
            return new Intl.DateTimeFormat('ru-RU', {
                hour: '2-digit',
                minute: '2-digit',
            }).format(date);
        } else if (isSameYear) {
            // Если совпадает год, но не совпадает день, выводим день, месяц и время
            return new Intl.DateTimeFormat('ru-RU', {
                day: 'numeric',
                month: 'short',
                hour: '2-digit',
                minute: '2-digit',
            }).format(date);
        } else {
            // Если не совпадает год, выводим день, месяц, год и время
            return new Intl.DateTimeFormat('ru-RU', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
            }).format(date);
        }
    }
}

const utils = new Utils();
export default utils;