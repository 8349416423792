import {List} from "semantic-ui-react";
import React from "react";
import UnloadSettingsStorage from "../../Stores/UnloadSettingsStorage";
import unloadsSettingsStorage from "../../Stores/UnloadsSettingsStorage";
import {observer} from "mobx-react-lite";
import DeleteButton from "../Shared/DeleteButton";

function UnloadRaw(props: { unload: UnloadSettingsStorage }) {
    const {unload} = props;
    return <List.Item active={unload.id === unloadsSettingsStorage.currentUnload?.id}
                      onClick={() => unloadsSettingsStorage.selectUnload(unload)}>
        {!unload.isMain && <DeleteButton onDelete={() => unloadsSettingsStorage.startDelete(unload)}/>}
        {unload.name}
    </List.Item>
}

export default observer(UnloadRaw);
