import React from 'react';
import { Grid, Label } from 'semantic-ui-react';

interface IDashboardItemProps {
    caption: string;
    value: string;
    wide?: boolean;
}

export function DashboardItem(props: IDashboardItemProps) {
    return (
        <>
            <Grid.Column width={props.wide ? '6' : '5'} className='dashboardItem'>
                <Label className='dashboardItemMainLabel shadow'>
                    <Label className='no-left-right-margin'>{props.caption}</Label>
                    <Label color='teal' className='dashboardItemValueLabel'>{props.value}</Label>
                </Label>
            </Grid.Column>
        </>
    );
}
