import {Checkbox, List} from "semantic-ui-react";
import React from "react";
import UnloadStore from "../../Entities/UnloadStore";
import {observer} from "mobx-react-lite";

function UnloadStoreRaw(props: { unloadStore: UnloadStore, storeName: string }) {
    const {unloadStore, storeName} = props;
    return <List.Item>
        <Checkbox label={storeName} checked={unloadStore.isActive}
                  onChange={(_, data) => unloadStore.changeActive(data.checked!)}/>
    </List.Item>
}

export default observer(UnloadStoreRaw);
