import React from "react";
import {Checkbox, Icon, Popup} from "semantic-ui-react";

interface ICheckboxWithTipProps {
    label: string;
    tip: string;
    checked: boolean;
    onChange: (checked: boolean) => void;
    isFirst?: boolean;
    slider?: boolean;
    disabled?: boolean;
}

function CheckboxWithTip(props: ICheckboxWithTipProps) {
    const {isFirst, tip, onChange, checked, label, slider, disabled} = props;
    return (
        <div>
            <Checkbox style={{ 'marginTop': isFirst ? '23px' : '10px' }}
                      label={label}
                      checked={checked}
                      onChange={(_, data) => onChange(data.checked!)}
                      slider={slider}
                      disabled={disabled}
            />
            <Popup
                content={tip}
                trigger={<Icon style={{marginLeft: '5px', cursor: 'pointer'}} name={"question circle outline"} />}
            />
        </div>
    )
}

export default CheckboxWithTip;