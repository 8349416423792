import { observer } from 'mobx-react-lite';
import React from 'react';
import { Grid } from 'semantic-ui-react';
import TemplateList from './TemplateList';
import templateListStore from "../../Stores/TemplateListStore";
import TemplateComponent from "./Template/Template";

function Templates() {
    return (
        <Grid>
            <Grid.Column computer={'3'}>
                <TemplateList />
            </Grid.Column>
            <Grid.Column width={'10'}>
                {templateListStore.selected && <TemplateComponent template={templateListStore.selected} />}
            </Grid.Column>
        </Grid>
    )
}

export default observer(Templates);