import { Dropdown, Header } from "semantic-ui-react";
import React from 'react';
import { observer } from "mobx-react-lite";
import skladStore from "../../../Stores/SkladStore";

function BundleSalePriceSelector() {
    return (
        <>
            <Header as='h5' textAlign='center' style={{marginTop: '10px'}}>
                Цена для рассчета комплекта
            </Header>
            <Dropdown
                className='shadow'
                style={{marginBottom: '10px', marginTop: '5px'}}
                fluid
                selection
                name='default'
                options={skladStore.salePrices.map((f) => { return { text: f.name, value: f.id }; })}
                value={skladStore.bundleSalePriceId || ''}
                onChange={(_, data) => {
                        skladStore.bundleSalePriceId = data.value ? (data.value as string) : null;
                        skladStore.hasChanges = true;
                    }
                }
                placeholder='Выберите поле'
                clearable />
        </>
    );
}

export default observer(BundleSalePriceSelector);