import {AvitoStatus} from "../Entities/Order/AvitoStatus";

const orderStatusConverter = {
    getStatusName(status: AvitoStatus) {
        switch (status) {
            case AvitoStatus.OnConfirmation:
                return "Ожидает подтверждения";
            case AvitoStatus.ReadyToShip:
                return "Ждет отправки";
            case AvitoStatus.InTransit:
                return "В пути";
            case AvitoStatus.Canceled:
                return "Отменён";
            case AvitoStatus.Delivered:
                return "Доставлен покупателю";
            case AvitoStatus.OnReturn:
                return "На возврате";
            case AvitoStatus.InDispute:
                return "Открыт спор";
            case AvitoStatus.Closed:
                return "Закрыт";
        }
    }
};

export default orderStatusConverter;