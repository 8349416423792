import {Button, Input} from "semantic-ui-react";
import React from "react";
import {observer} from "mobx-react-lite";
import usersStore from "../UsersStore";

function AddUserButton() {
    if (usersStore.userAddingIsStarted) {
        return <Input autoFocus
                      loading={usersStore.userCreatingInProcess}
                      onBlur={() => usersStore.stopAddingUser()}
                      onChange={e => usersStore.changeNewUserToken(e.target.value)}
                      value={usersStore.newUserToken}
                      placeholder={'Api token'}
                      onKeyDown={(e: any) => {
                          if (e.key === 'Enter') {
                              void usersStore.addUser();
                          }
                          if (e.key === 'Escape') {
                              usersStore.stopAddingUser()
                          }
                      }}/>
    }
    return <Button basic primary onClick={() => usersStore.startAddingUser()}>Добавить юзера</Button>
}

export default observer(AddUserButton);