import { observable } from "mobx";
import errorConverter from "../Services/ErrorConverter";
interface IErrorStore {
    errors: string[];
    isOpen: boolean;
    open: () => void;
    close: () => void;
    add: (error: string) => void;
}

const errorStore = observable<IErrorStore>({
    errors: [],
    isOpen: false,
    open() {
        errorStore.isOpen = true;
    },
    close() {
        errorStore.isOpen = false;
        errorStore.errors.length = 0;
    },
    add(error: string) {
        errorStore.errors.push(errorConverter.getCaptionFormCode(error));
        errorStore.open();
    }
})

export default errorStore;
