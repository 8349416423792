import {Button} from "semantic-ui-react";
import React from "react";

function FieldCreatingButton(props: { onStartCreating: () => void }) {
    return <Button content='Добавить поле'
                   icon='add'
                   fluid
                   basic
                   onClick={() => props.onStartCreating()}
                   labelPosition='right'/>;
}

export default FieldCreatingButton;
