import React from "react";
import {observer} from "mobx-react-lite";
import dictionaryStore from "../../Stores/Dictioanaries/DictionaryStore";
import DictionaryItemRaw from "./DictionaryItemRaw";

function DictionaryItems() {
    return <>
        {
            dictionaryStore.dictionary?.items.map(item =>
                <DictionaryItemRaw item={item}/>)
        }
    </>
}

export default observer(DictionaryItems);