import React from "react";
import {Route, Switch, useLocation} from "react-router-dom";
import VariantWidgetPage from "./Variant/VariantWidgetPage";
import OrderWidgetPage from "./Order/OrderWidgetPage";
export const routeName = "widgets";
function WidgetsRouter() {
    const query = new URLSearchParams(useLocation().search);
    const contextKey = query.get("contextKey");
    if (!contextKey) {
        return <></>;
    }
    
    return <div style={{backgroundColor: '#f7f7f7', height: '100vh'}}>
        <Switch>
            <Route path={`/${routeName}/variant`}>
                <VariantWidgetPage contextKey={contextKey} />
            </Route>
            <Route path={`/${routeName}/order`}>
                <OrderWidgetPage contextKey={contextKey} />
            </Route>
        </Switch>
    </div>
}

export default WidgetsRouter;