import {observer} from "mobx-react-lite";
import React from "react";
import {Grid, Label, Table} from "semantic-ui-react";
import analyticsStore from "./AnalyticsStore";

function AnalyticsBlock() {
    if (!analyticsStore.visible) {
        return <></>
    }
    return <Grid>
        <Grid.Row>
            <Grid.Column width={8}>
                <Label basic color={'teal'}>Ждём оплаты</Label>
                <Table color={'teal'} compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Имя тарифа</Table.HeaderCell>
                            <Table.HeaderCell>Цена</Table.HeaderCell>
                            <Table.HeaderCell>Подписок</Table.HeaderCell>
                            <Table.HeaderCell>Деньги</Table.HeaderCell>
                            <Table.HeaderCell>Чистые деньги</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {analyticsStore.awaitingPayments.map(x => <Table.Row>
                            <Table.Cell>{x.tariff.name}</Table.Cell>
                            <Table.Cell>{x.tariff.price}</Table.Cell>
                            <Table.Cell>{x.count}</Table.Cell>
                            <Table.Cell>{x.money}</Table.Cell>
                            <Table.Cell>{x.cleanMoney}</Table.Cell>
                        </Table.Row>)}
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell>Итого</Table.HeaderCell>
                            <Table.HeaderCell />
                            <Table.HeaderCell>{analyticsStore.awaitingPayments.reduce((sum, current) => sum + current.count, 0)}</Table.HeaderCell>
                            <Table.HeaderCell>{analyticsStore.awaitingPayments.reduce((sum, current) => sum + current.money, 0)}</Table.HeaderCell>
                            <Table.HeaderCell>{analyticsStore.awaitingPayments.reduce((sum, current) => sum + current.cleanMoney, 0)}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            </Grid.Column>
        </Grid.Row>
    </Grid>
}

export default observer(AnalyticsBlock);