import {observer} from 'mobx-react-lite';
import React from 'react'
import {Button, Form, Grid, Input, Label, Message, Popup, TextArea} from 'semantic-ui-react';
import CustomFieldSelector from './Selectors/CustomFieldSelector';
import FieldValueSelector from './Selectors/FieldValueSelector';
import BasicFieldSelector from './Selectors/BasicFieldSelector';
import skladStore from "../../../../Stores/SkladStore";
import FieldStore from "../../../../Entities/TemplateFieldValue";
import Template from "../../../../Entities/Template";

interface IFieldProps {
    field: FieldStore
    template: Template
    showCode?: boolean;
    tip?: string;
}

function Field(props: IFieldProps) {
    const {field, template, showCode, tip} = props;
    const isDescription = field.code === "Description";
    const isId = field.code === "Id";

    function addTag(value: string, custom: boolean) {
        if (custom) {
            field.value += '[$' + value + '$]';
        } else {
            field.value += '[!' + value + '!]';
        }
        template.isChanged = true;
    }

    function addFileFieldTag(value: string) {
        field.value += '[%' + value + '%]';
        template.isChanged = true;
    }

    function selectValue(value: string) {
        if (isDescription) {
            field.value += value;
        } else if (field.isMultiply) {
            if (field.value) {
                field.value += ',';
            }
            field.value += value;
        } else {
            field.value = value;
        }

        template.isChanged = true;
    }

    function renderButtons() {
        return (
            <>
                {field.values &&
                    <FieldValueSelector values={field.values} 
                                        onChecked={selectValue} 
                                        fixHeight={isDescription}
                                        showMultiplyModeEditor={field.showMultiplyModeEditor}
                                        multiplyModeEnabled={field.isMultiply}
                                        onSwitchMultiplyMode={() => {
                                            field.switchMultiplyMode(); 
                                            template.isChanged = true;
                                        }}
                    />}
                {skladStore.baseFields && skladStore.baseFields.length > 0 &&
                    <BasicFieldSelector onChecked={addTag} fixHeight={isDescription}/>}
                {skladStore.hasCustomFields &&
                    <CustomFieldSelector showFileFields={!isId} onChecked={addTag} onCheckedFileField={addFileFieldTag}
                                         fixHeight={isDescription}/>}
                {tip && <Popup
                    position={'bottom right'}
                    content={tip}
                    trigger={<Button style={{borderTopLeftRadius: '0', borderBottomLeftRadius: '0', marginRight: '0'}}
                                     icon='help'/>}/>}
                {template.historyIsVisible && !field.historyIsVisible && field.templateFieldId &&
                    <Button className={"fix-height"} loading={field.historyIsLoading} icon={'history'} onClick={() => field.loadHistory(template.id)} />
                }
            </>
        )
    }

    const FieldName = <Input
        fluid
        value={field.name}
        readOnly={true}
        label={field.isRequired && {icon: 'asterisk'}}
        labelPosition='right corner'
        title={field.name}
    />;
    return (
        <Grid.Row>
            <Grid.Column width={5} textAlign='center'>
                {FieldName}
                {showCode &&
                    <div style={{
                        position: 'absolute',
                        color: 'gray',
                        fontSize: 'small',
                        left: '20px'
                    }}>{field.code}</div>}
            </Grid.Column>
            <Grid.Column width={11} verticalAlign='top'>
                <Input
                    fluid
                    action
                    value={field?.value}
                    onChange={e => {
                        field.value = e.target.value;
                        template.isChanged = true;
                    }}
                    color='teal'
                    placeholder={isId ? "Заполнить автоматически" : ""}
                >
                    {!isDescription ? <input/> :
                        <Form style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                            <TextArea
                                rows={3}
                                style={{minHeight: 100, maxHeight: '300px'}}
                                value={field?.value} onChange={(_, data) => {
                                field.value = data.value as string;
                                template.isChanged = true;
                            }}/>
                        </Form>
                    }
                    {renderButtons()}
                </Input>
                {template.historyIsVisible && field.historyIsVisible &&
                    <>
                        <Message onDismiss={() => field.hideHistory()}>
                            <Message.Header style={{marginBottom: '10px'}}>Список изменений</Message.Header>
                            {field.history.length > 0 
                                ? <Message.Content>
                                    {field.history.map(historyItem =>
                                        <p>
                                            <Label color={'blue'}>{historyItem.createdOn}</Label>
                                            Изменено на:
                                            <Label color={'green'}>{historyItem.newValue ? historyItem.newValue : '-Пустое значение-'}</Label>
                                        </p>
                                    )}
                                </Message.Content> 
                                : <Message.Content>Нет данных</Message.Content>
                            }
                        </Message>
                    </>
                }
            </Grid.Column>
        </Grid.Row>
    )
}

export default observer(Field);
