import {computed, observable, runInAction} from "mobx";
import api from "../Services/Api";
import {FileDto, IntegrationLog, SubscriptionDto} from "../Entities/Dtos";
import websocketEventConsumer from "../Services/WebsocketEventConsumer";
import utils from "../Services/Utils";

const defaultValue: string = "Нет данных";
class ProfileInfoStore {
    @observable userId: string = "";
    @observable subscription?: SubscriptionDto;
    @observable files: FileDto[] = [];
    @observable downloadUrl: string = "";
    @observable integrations: IntegrationLog[] = [];
    @observable productCount: number = 0;
    @observable activeFoldersCount: number = 0;
    @observable activeStoresCount: number = 0;
    @observable lastExecution: string = defaultValue;
    @observable nextExecution: string = defaultValue;
    @observable lastJobState: string = defaultValue;
    @observable isLoading: boolean = false;
    @observable jobIsRunning: boolean = false;
    @observable lastJobStartExecuting: string = '-';
    @observable isLoaded: boolean = false;
    @observable messengerIsEnabled: boolean = false;
    @observable orderIntegrationIsEnabled: boolean = false;
    @observable showOrderIntegrationSettings: boolean = false;
    @observable logUrl?: string;
    
    async load() {
        const result = await api.get("profile/info");
        this.isLoading = true;
        if (result.success) {
            runInAction(() => {
                this.lastJobState = result.data.lastJobState ?? defaultValue;
                this.nextExecution = result.data.nextExecution ?? defaultValue;
                this.lastExecution = result.data.lastExecution ?? defaultValue;
                this.activeStoresCount = result.data.activeStoresCount;
                this.activeFoldersCount = result.data.activeFoldersCount;
                this.productCount = result.data.productCount;
                this.integrations = result.data.integrations;
                this.files = result.data.files;
                this.logUrl = result.data.logUrl;
                this.userId = result.data.userId;
                this.subscription = result.data.subscription;
                this.isLoading = false;
                this.lastJobStartExecuting = result.data.lastJobStartExecuting;
                this.jobIsRunning = result.data.jobIsRunning;
                this.downloadUrl = result.data.downloadUrl;
                this.messengerIsEnabled = result.data.messengerIsEnabled;
                this.orderIntegrationIsEnabled = result.data.orderIntegrationIsEnabled;
                this.showOrderIntegrationSettings = result.data.showOrderIntegrationSettings;
                this.isLoaded = true;
            });

            await websocketEventConsumer.consumeToUserEvents(this.userId);
        }
    }
    
    @computed get subscriptionInfo(): string | undefined {
        if (!this.subscription) {
            return undefined;
        }
        
        if (this.subscription.expiredOn) {
            return "Тариф " + this.subscription.tariffName + " до " + utils.getLocalizedOnlyDateString(this.subscription.expiredOn);
        }

        return "Тариф " + this.subscription.tariffName;
    }
}

const profileInfoStore = new ProfileInfoStore();
export default profileInfoStore;