import {observable} from "mobx";
import api from "../Services/Api";
import {TemplateFieldHistoryItemDto} from "./Dtos";

export default class TemplateFieldValue {
    id: string; //fieldId
    @observable name: string;
    @observable code: string;
    @observable templateFieldId: string | null; //id
    @observable description: string;
    @observable isMultiply: boolean;
    @observable showMultiplyModeEditor: boolean;
    @observable value: string;
    @observable isRequired: boolean;
    @observable values: string[] | null;
    @observable historyIsVisible: boolean = false;
    @observable historyIsLoading: boolean = false;
    @observable history: TemplateFieldHistoryItemDto[] = [];

    constructor(id: string, name: string, templateFieldId: string | null, description: string, code: string, value: string, isRequired: boolean, values: string[] | null, isMultiply: boolean, showMultiplyModeEditor: boolean) {
        this.id = id;
        this.name = name;
        this.templateFieldId = templateFieldId;
        this.description = description;
        this.value = value;
        this.isRequired = isRequired;
        this.values = values;
        this.code = code;
        this.isMultiply = isMultiply;
        this.showMultiplyModeEditor = showMultiplyModeEditor;
    }

    async loadHistory(templateId: string) {
        this.changeHistoryIsLoading(true);
        const result = await api.get("template/fields.history?templateId="
            + templateId + "&templateFieldId=" + this.templateFieldId);
        if(result.success) {
            this.history = result.data as TemplateFieldHistoryItemDto[];
        }
        this.changeHistoryIsLoading(false);
        this.showHistory();
    }

    changeValue(value: string) {
        this.value = value;
    }
    
    showHistory(){
        this.historyIsVisible = true;
    }

    hideHistory(){
        this.historyIsVisible = false;
    }
    
    changeHistoryIsLoading(value: boolean){
        this.historyIsLoading = value;
    }
    
    switchMultiplyMode(){
        this.isMultiply = !this.isMultiply;
    }
}
