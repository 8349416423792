import React, { useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import skladStore from "../../Stores/SkladStore";
import templateListStore from "../../Stores/TemplateListStore";
import TemplateMappingBlock from "./TemplateMappingBlock";

function TemplateMapping() {
    useEffect(() => {
        void skladStore.load();
        if (!templateListStore.isLoaded) {
            void templateListStore.load();
        }
    }, []);

    return (
        <>
            <Grid>
                <Grid.Column width='9'>
                    <TemplateMappingBlock />
                </Grid.Column>
            </Grid>
        </>
    )
}

export default observer(TemplateMapping);
