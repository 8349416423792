import React from "react";
import {Menu} from "semantic-ui-react";
import messengerStorage from "../../Stores/Messenger/MessengerStorage";
import MessengerAccountListItem from "./MessengerAccountListItem";
import {observer} from "mobx-react-lite";

function MessengerAccountList() {
    return <>
        <Menu pointing secondary>
            {messengerStorage.accounts.map(account => <MessengerAccountListItem key={account.id} account={account}/>)}
        </Menu>
    </>
}

export default observer(MessengerAccountList);