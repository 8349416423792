import React from "react";
import copy from "copy-to-clipboard";
import {observer} from "mobx-react-lite";
import store from "../../Stores/ProfileInfoStore";
import successStore from "../../Stores/SuccessStore";

function UserId() {
    return (
        <>
            {store.userId &&
                <p style={{opacity: '.55', cursor: 'pointer', textAlign: 'center', marginBottom: '0px'}}
                   onClick={() => {
                       copy(store.userId);
                       successStore.add("Код клиента скопирован")
                   }}>
                    Код клиента: {store.userId}
                </p>
            }
            {store.subscriptionInfo &&
                <p style={{opacity: '.55', textAlign: 'center'}}>{store.subscriptionInfo}</p>
            }
        </>
    )
}

export default observer(UserId);
