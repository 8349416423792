import FieldEditorStore from "../../Stores/FieldEditorStore";
import {observer} from "mobx-react-lite";
import React from "react";
import FieldEditor from "../Shared/FieldEditor";
import FieldCreatingButton from "../Shared/FieldCreatingButton";
import FieldName from "../Shared/FieldName";
import unloadsSettingsStorage from "../../Stores/UnloadsSettingsStorage";

const store = new FieldEditorStore('settings/unloadField.create', () => unloadsSettingsStorage.load());

function NewUnloadField() {
    return <FieldName>
        {store.isEditing ? <FieldEditor store={store}/> :
            <FieldCreatingButton onStartCreating={() => store.startEditing()}/>}
    </FieldName>
}

export default observer(NewUnloadField);
