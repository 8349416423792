import {List} from "semantic-ui-react";
import storesFieldValuesStorage from "../../Stores/StoresSettingsStorage";
import React from "react";
import {observer} from "mobx-react-lite";
import StoreSettingsStorage from "../../Stores/StoreSettingsStorage";

function StoreRaw(props: { store: StoreSettingsStorage }) {
    const {store} = props;
    return <List.Item active={store.id === storesFieldValuesStorage.currentStore?.id}
                      onClick={() => storesFieldValuesStorage.selectStore(store)}>
        {store.name}
    </List.Item>
}

export default observer(StoreRaw);
