import React from "react";
import dictionariesStore from "../../Stores/Dictioanaries/DictionariesStore";
import ConfirmDelete from "../Shared/ConfirmDelete";
import {useHistory} from "react-router-dom";
import dictionaryStore from "../../Stores/Dictioanaries/DictionaryStore";
import {observer} from "mobx-react-lite";
function DeleteDictionaryButton() {
    const history = useHistory();
    function onSuccessDeletion() {
        if (dictionariesStore.dictionaryToDelete?.id === dictionaryStore.id) {
            history.replace('/dictionaries');
        }
    }
    
    return <ConfirmDelete isOpen={dictionariesStore.dictionaryToDelete !== undefined}
                          name={dictionariesStore.dictionaryToDelete?.name}
                          isLoading={dictionariesStore.isDeleting}
                          onConfirm={() => void dictionariesStore.delete(() => onSuccessDeletion())}
                          onCancel={() => dictionariesStore.stopDeleting()}
    />
}

export default observer(DeleteDictionaryButton);