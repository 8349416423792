import {observable} from "mobx";
import IHasIdAndName from "../Entities/IHasIdAndName";
import api from "../Services/Api";

class OrderLookupStorage {
    @observable loading: boolean = false;
    @observable organizations: IHasIdAndName[] = [];
    @observable departments: IHasIdAndName[] = [];
    @observable salesChannels: IHasIdAndName[] = [];
    @observable orderStates: IHasIdAndName[] = [];
    @observable orderFields: IHasIdAndName[] = [];
    @observable companyTypes: IHasIdAndName[] = [];

    async load() {
        this.loading = true;
        try {
            let result = await api.get("organization/list");
            if (result.success) {
                this.organizations = result.data as IHasIdAndName[];

                result = await api.get("salesChannel/list");
                if (result.success) {
                    this.salesChannels = result.data as IHasIdAndName[];
                }

                result = await api.get("department/list");
                if (result.success) {
                    this.departments = result.data as IHasIdAndName[];
                }

                result = await api.get("order/state/list");
                if (result.success) {
                    this.orderStates = result.data as IHasIdAndName[];
                }
                
                result = await api.get("order/field/list");
                if (result.success) {
                    this.orderFields = result.data as IHasIdAndName[];
                }

                result = await api.get("companyType/list");
                if (result.success) {
                    this.companyTypes = result.data as IHasIdAndName[];
                }
            }
        } finally {
            this.loading = false;
        }
    }
}

const orderLookupStorage= new OrderLookupStorage();
export default orderLookupStorage;