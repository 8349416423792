import React from 'react';
import {Button, Dropdown, Input} from 'semantic-ui-react';
import copy from "copy-to-clipboard";
import {observer} from 'mobx-react-lite';
import successStore from "../../Stores/SuccessStore";
import {FileDto} from "../../Entities/Dtos";

interface IXmlBlockProps {
    file: FileDto;
    downloadUrl: string;
}

function XmlHrefBlock(props: IXmlBlockProps) {
    const fileName = props.file.name;
    return (
        <>
            <Input fluid style={{'marginTop': '7px'}} type={'text'} action>
                <input value={props.file.xmlUrl}
                       readOnly
                       onClick={() => {
                           copy(props.file.xmlUrl);
                           successStore.add("Адрес скопирован!")
                       }}/>
                <Button.Group color={'teal'}>
                    <Button style={{borderRadius: '0'}} icon={'eye'} onClick={() => {
                        window.open(props.file.htmlUrl);
                    }}/>
                    <Dropdown icon={'ellipsis horizontal'} className='button icon' floating>
                        <Dropdown.Menu>
                            <Dropdown.Item icon={'download'}
                                           text='Скачать XML'
                                           as={'a'}
                                           href={props.downloadUrl + '/xml/' + fileName}/>
                            <Dropdown.Item icon={'download'}
                                           text='Скачать HTML'
                                           as={'a'}
                                           href={props.downloadUrl + '/html/' + fileName}/>
                            <Dropdown.Item icon={'download'}
                                           text='Скачать остатки'
                                           as={'a'}
                                           href={props.downloadUrl + '/stock/' + fileName}/>
                            <Dropdown.Divider />
                            <Dropdown.Header content={'Объявлений: '+ props.file.productsCount} />
                        </Dropdown.Menu>
                    </Dropdown>
                </Button.Group>
            </Input>
        </>
    );
}

export default observer(XmlHrefBlock);
