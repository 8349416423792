import {observer} from 'mobx-react-lite'
import React from 'react'
import {Button} from "semantic-ui-react";
import skladStore from "../../../../Stores/SkladStore";
import profileInfoStore from "../../../../Stores/ProfileInfoStore";

function CreateFeedButton() {
    const isDisabled = skladStore.jobIsStopping || skladStore.isSaving || skladStore.hasChanges;
    const color = profileInfoStore.jobIsRunning ? 'red' : 'blue';
    const text = profileInfoStore.jobIsRunning ? 'Остановить выгрузку' : 'Выгрузить сейчас';
    return (
        <Button disabled={isDisabled}
                basic compact color={color}
                onClick={() => skladStore.tryRunJob()}
        >{text}</Button>
    )
}

export default observer(CreateFeedButton);