import React from "react";
import {observer} from "mobx-react-lite";
import FieldEditorStore from "../../Stores/FieldEditorStore";
import FieldName from "../Shared/FieldName";
import FieldEditor from "../Shared/FieldEditor";
import FieldCreatingButton from "../Shared/FieldCreatingButton";
import storesSettingsStorage from "../../Stores/StoresSettingsStorage";

const store = new FieldEditorStore('settings/storeField.create', () => storesSettingsStorage.load());

function NewStoreField() {
    return <FieldName>
        {store.isEditing ? <FieldEditor store={store}/> :
            <FieldCreatingButton onStartCreating={() => store.startEditing()}/>}
    </FieldName>
}

export default observer(NewStoreField);
