import React from "react";
import {Grid} from "semantic-ui-react";
import {SemanticWIDTHS} from "semantic-ui-react/dist/commonjs/generic";

function FieldValue(props: { children: React.ReactNode, style?: any, visible?: boolean, width?: SemanticWIDTHS }) {
    if (props.visible !== undefined && !props.visible) {
        return <></>;
    }
    return <Grid.Column style={props.style} width={props.width ?? 11}>{props.children}</Grid.Column>
}

export default FieldValue;
