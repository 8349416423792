import React from "react";
import { Label, SemanticCOLORS } from "semantic-ui-react";

import {AvitoStatus} from "../../../Entities/Order/AvitoStatus";
import orderStatusConverter from "../../../Services/OrderStatusConverter";

export function Status(props: { status: AvitoStatus }) {
    function getColorForStatus(status: AvitoStatus): SemanticCOLORS {
        switch (status) {
            case AvitoStatus.OnConfirmation:
                return 'teal'
            case AvitoStatus.ReadyToShip:
            case AvitoStatus.InTransit:
                return 'blue'
            case AvitoStatus.Canceled:
                return 'grey'
            case AvitoStatus.Delivered:
                return 'green'
            case AvitoStatus.OnReturn:
            case AvitoStatus.InDispute:
                return 'orange'
            case AvitoStatus.Closed:
                return 'grey'
        }
    }

    const color = getColorForStatus(props.status);
    return <Label color={color} horizontal>
        {orderStatusConverter.getStatusName(props.status)}
    </Label>
}