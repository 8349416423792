import {observable, runInAction} from "mobx";

import api from "../Services/Api";
import {OrderInfo} from "../Entities/Order/OrderInfo";
import {OrderActionType} from "../Entities/Order/OrderActionType";
import utils from "../Services/Utils";
import successStore from "./SuccessStore";

class OrderStore {
    id: string = "";
    @observable order?: OrderInfo;
    @observable loading: boolean = false;
    @observable trackNumberDialogOpened: boolean = false;
    @observable error?: string;
    @observable trackNumber: string = "";
    @observable currentAction?: OrderActionType;
    @observable changeStatusConfirmationDialogOpened: boolean = false;
    @observable debugInfo?: DebugInfo;
    
    public async init(id: string) {
        this.id = id;
        await this.load();
    }
    
    async load() {
        this.error = undefined;
        this.trackNumber = "";
        this.loading = true;
        this.debugInfo = undefined;

        try {
            const result = await api.get(`order/info?id=${this.id}`);
            if(result.success) {
                runInAction(()=> {
                    this.order = result.data;
                });
            }
        }
        finally {
            this.loading = false;
        }
    }
    
    async loadDebugInfo() {
        if (!this.debugInfo) {
            this.debugInfo = new DebugInfo(this.id, this.order?.moySkladUrl != undefined);
        }
        if (this.debugInfo) {
            await this.debugInfo.load();
        }
    }
    
    async forceSendToMs() {
        const result = await api.post('order/debug/forceSendToMs', {
            id: this.id,
        });
        if (result.success) {
            successStore.add("Задача запущена")
        }
    }

    get canSetTrackNumber(): boolean {
        if(this.loading || !this.order) {
            return false;
        }
        return this.order.availableActions.some((action) => action.type === OrderActionType.SetTrackNumber
            || action.type === OrderActionType.FixTrackNumber);
    }

    get canConfirm(): boolean {
        if(this.loading || !this.order) {
            return false;
        }
        return this.order.availableActions.some((action) => action.type === OrderActionType.Confirm);
    }

    get canReject(): boolean {
        if(this.loading || !this.order) {
            return false;
        }
        return this.order.availableActions.some((action) => action.type === OrderActionType.Reject);
    }

    get canPerform(): boolean {
        if(this.loading || !this.order) {
            return false;
        }
        return this.order.availableActions.some((action) => action.type === OrderActionType.Perform);
    }

    get canReceive(): boolean {
        if(this.loading || !this.order) {
            return false;
        }
        return this.order.availableActions.some((action) => action.type === OrderActionType.Receive);
    }

    openTrackNumberDialog() {
        this.trackNumberDialogOpened = true;
    }

    closeTrackNumberDialog() {
        this.trackNumber = "";
        this.trackNumberDialogOpened = false;
    }

    setTrackNumber(trackNumber: string){
        this.trackNumber = trackNumber;
    }

    async saveTrackNumber() {
        if(!this.trackNumber) {
            this.error = "Необходимо заполнить трек номер";
            return;
        } else {
            this.error = undefined;
        }
        
        this.loading = true;

        try {
            const result = await api.post('order/setTrackingNumber', {
                orderId: this.id,
                trackingNumber: this.trackNumber
            });
            if(result.success) {
                this.closeTrackNumberDialog();
                await this.load();
            } else {
                this.error = result.error;
            }
        }
        finally {
            this.loading = false;
        }
    }

    openChangeStatusConfirmation(actionType: OrderActionType) {
        this.currentAction = actionType;
        this.changeStatusConfirmationDialogOpened = true;
    }

    async changeStatus() {
        if(!this.currentAction) {
            return;
        }
        this.loading = true;

        try {
            const result = await api.post('order/changeStatus', {
                orderId: this.id,
                action: this.currentAction
            });
            if(result.success) {
                this.closeChangeStatusConfirmationDialog();
                await this.load();
            } else {
                this.error = result.error;
            }
        }
        finally {
            this.loading = false;
        }
    }
    
    closeChangeStatusConfirmationDialog() {
        this.currentAction = undefined;
        this.changeStatusConfirmationDialogOpened = false;
    }
}

export class DebugInfo {
    @observable avitoInfo?: string;
    @observable skladInfo?: string;
    @observable isLoading: boolean = false;
    orderId: string;
    needLoadSkladInfo: boolean;
    
    constructor(orderId: string, needLoadSkladInfo: boolean) {
        this.orderId = orderId;
        this.needLoadSkladInfo = needLoadSkladInfo;
    }
    async load() {
        this.isLoading = true;
        let result = await api.get(`order/debug/avitoInfo?id=${this.orderId}`);
        if (result.success) {
            this.avitoInfo = utils.beautifyJson(result.data);
        }

        if (this.needLoadSkladInfo) {
            result = await api.get(`order/debug/skladInfo?id=${this.orderId}`);
            if (result.success) {
                this.skladInfo = utils.beautifyJson(result.data);
            }
        }

        this.isLoading = false;
    }
    get hasData() {
        return this.avitoInfo || this.skladInfo;
    }
}


const orderStore = new OrderStore();

export default orderStore;