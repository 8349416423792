import React from "react";
import {observer} from "mobx-react-lite";
import {List} from "semantic-ui-react";
import DeleteButton from "../Shared/DeleteButton";
import {DictionaryDto} from "../../Entities/Dtos";
import dictionariesStore from "../../Stores/Dictioanaries/DictionariesStore";
import {useHistory} from "react-router-dom";
import dictionaryStore from "../../Stores/Dictioanaries/DictionaryStore";

function DictionaryRaw(props: { dictionary: DictionaryDto }) {
    const history = useHistory();
    const {dictionary} = props;
    return <List.Item active={dictionary.id === dictionaryStore.id}
                      onClick={() => history.push(`/dictionaries/${dictionary.id}`)}>
        <DeleteButton onDelete={() => dictionariesStore.markToDelete(dictionary)}/>
        {dictionary.name}
    </List.Item>
}

export default observer(DictionaryRaw);