import {observable} from "mobx";
import api from "../Services/Api";
import {AvitoAccountDto} from "../Entities/Dtos";
import unloadsSettingsStorage from "./UnloadsSettingsStorage";
import AvitoAccountSettingsStorage from "./AvitoAccountSettingsStorage";
import profileInfoStore from "./ProfileInfoStore";
import orderLookupStorage from "./OrderLookupStorage";

class AvitoAccountsSettingsStorage {
    @observable accounts: AvitoAccountSettingsStorage[] = [];
    @observable current?: AvitoAccountSettingsStorage;
    @observable loading: boolean = false;
    @observable isLoaded: boolean = false;
    @observable deletingAccount?: AvitoAccountSettingsStorage;
    @observable enableMessenger: boolean = false;

    async load() {
        this.changeLoading(true);
        if (!unloadsSettingsStorage.isLoaded) {
            await unloadsSettingsStorage.load();
        }

        await orderLookupStorage.load();

        try {
            const result = await api.get("avitoAccounts/list");
            if (result.success) {
                const response = result.data as AvitoAccountDto[];

                this.accounts = response.map(x =>
                    new AvitoAccountSettingsStorage(x.name, x.id, x.clientId, x.clientSecret, 
                        x.updatingTokenError,
                        x.unloadId, 
                        x.messengerIsEnabled, x.orderIntegrationIsEnabled, 
                        x.orderIntegrationSettings, 
                        x.stockTransferIsEnabled, 
                        x.priceForTransferId, 
                        x.adUrlFieldId,
                        x.avitoIdFieldId));
                this.enableMessenger = this.accounts.some(x=> x.messengerIsEnabled);
                if(this.accounts.length > 0) {
                    if (this.current && !this.current.isNew) {
                        this.current = this.accounts.filter(x => x.id === this.current?.id)[0];
                        if (!this.current) {
                            this.selectAccount(this.accounts[0]);
                        }
                    } else {
                        this.selectAccount(this.accounts[0]);
                    }
                }

                this.cancelDelete();
                this.isLoaded = true;
            }
        } finally {
            this.changeLoading(false);
        }
    }

    selectAccount(account: AvitoAccountSettingsStorage) {
        if (!account) return;
        this.current = account;
    }

    startDelete(account: AvitoAccountSettingsStorage) {
        if (account.isNew) {
            this.accounts.length = this.accounts.length - 1;
            this.current = this.accounts[0];
            return;
        }
        this.deletingAccount = account;
    }
    cancelDelete() {
        this.deletingAccount = undefined;
    }
    async applyDelete() {
        if (!this.deletingAccount) {
            return;
        }
        await this.deletingAccount.delete();
        this.current = this.accounts[0];
    }

    startCreatingAccount() {
        const newAccount = new AvitoAccountSettingsStorage('Новый аккаунт');
        this.accounts.push(newAccount);
        this.selectAccount(newAccount);
    }

    get hasNew(): boolean {
        return this.accounts.some(x => x.isNew);
    }

    private changeLoading(value: boolean) {
        this.loading = value;
    }
    
    get canShowMessenger() {
        if (!profileInfoStore.isLoaded) void profileInfoStore.load();

        if (this.isLoaded) {
            return this.enableMessenger;
        }

        return profileInfoStore.messengerIsEnabled;
    }

    get canShowOrders() {
        if (this.isLoaded) {
            return this.accounts.some(x=> x.orderIntegrationIsEnabled);
        }
        
        return profileInfoStore.orderIntegrationIsEnabled;
    }
}

const avitoAccountsSettingsStorage = new AvitoAccountsSettingsStorage();
export default avitoAccountsSettingsStorage;
