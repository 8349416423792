import {Grid, Input} from "semantic-ui-react";
import React from "react";
import {SemanticWIDTHS} from "semantic-ui-react/dist/commonjs/generic";

function FieldName(props: { name?: string, children?: React.ReactNode, required?: boolean, width?: SemanticWIDTHS }) {
    const {name, children, required, width} = props;
    return <Grid.Column width={width ?? 5}>
        {children ?? <Input
            readOnly
            label={required ? {icon: 'asterisk'}: undefined}
            labelPosition='right corner'
            value={name}
            fluid/>}
    </Grid.Column>
}

export default FieldName;
