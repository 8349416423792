import { observable } from "mobx";
import localizator from "./Localizator";
interface ISuccessStore {
    messages: string[];
    isOpen: boolean;
    open: () => void;
    close: () => void;
    add: (message: string) => void;
}

const successStore = observable<ISuccessStore>({
    messages: [],
    isOpen: false,
    open() {
        successStore.isOpen = true;
    },
    close() {
        successStore.isOpen = false;
        successStore.messages.length = 0;
    },
    add(message: string) {
        successStore.messages.push(localizator.getMessage(message));
        successStore.open();
    }
})

export default successStore;
