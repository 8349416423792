import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {
    Button,
    Form,
    FormField,
    Grid,
    Header,
    Segment,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHeader,
    TableHeaderCell,
    TableRow,
    FormInput,
    ModalHeader,
    Modal,
    ModalContent,
    ModalActions,
    Confirm
} from "semantic-ui-react";
import {useHistory, useParams} from "react-router-dom";

import orderStore from "../../../Stores/OrderStore";
import {InformationPanelItem} from "../../Dashboard/InformationPanelItem";
import {OrderIntegrationLog} from "../../../Entities/Order/OrderIntegrationLog";
import {OrderInfo} from "../../../Entities/Order/OrderInfo";
import {Delivery} from "../../../Entities/Order/Delivery";
import {DeliveryServiceType} from "../../../Entities/Order/DeliveryServiceType";
import {Status} from "./Status";
import {OrderActionType} from "../../../Entities/Order/OrderActionType";
import utils from "../../../Services/Utils";
import {DebugButton, DebugInfoBlock} from "./OrderDebug";


function OrderPage() {
    const params = useParams<{ id: string }>();
    const history = useHistory();
    useEffect(() => {
        void orderStore.init(params.id);
    }, [params.id])

    if (!orderStore.order) {
        return <InformationPanelItem listPlaceholder={orderStore.loading}>
        </InformationPanelItem>
    }

    const order = orderStore.order;

    return <Grid>
        <TrackingNumberDialog/>
        <ChangeStatusConfirmationDialog/>
        <Grid.Row>
            <Grid.Column width={14}>
                <Segment textAlign='left'>
                    <Header as='h3'>Заказ {order.moySkladUrl ? <a target="_blank"
                                                                  href={order.moySkladUrl}>{order.marketplaceId}</a> : order.marketplaceId} от {utils.getLocalizedDateString(order.createdOn)}</Header>
                    <Button onClick={_ => history.goBack()}>Закрыть</Button>
                    <DebugButton />
                    {
                        orderStore.canSetTrackNumber &&
                        <Button color={'teal'}
                                labelPosition='right'
                                style={{marginLeft: '10px'}}
                                disabled={orderStore.loading}
                                icon={'box'}
                                content={'Указать трек номер'}
                                onClick={() => orderStore.openTrackNumberDialog()}
                        />
                    }
                    {
                        orderStore.canConfirm &&
                        <Button color={'teal'}
                                style={{marginLeft: '10px'}}
                                disabled={orderStore.loading}
                                content={'Подтвердить заказ'}
                                onClick={() => orderStore.openChangeStatusConfirmation(OrderActionType.Confirm)}
                        />
                    }
                    {
                        orderStore.canReject &&
                        <Button color={'red'}
                                style={{marginLeft: '10px'}}
                                disabled={orderStore.loading}
                                content={'Отменить заказ'}
                                onClick={() => orderStore.openChangeStatusConfirmation(OrderActionType.Reject)}
                        />
                    }
                    {
                        orderStore.canPerform &&
                        <Button color={'teal'}
                                style={{marginLeft: '10px'}}
                                disabled={orderStore.loading}
                                content={'Подтвердить отправку заказа (RDBS)'}
                                onClick={() => orderStore.openChangeStatusConfirmation(OrderActionType.Perform)}
                        />
                    }
                    {
                        orderStore.canReceive &&
                        <Button color={'green'}
                                style={{marginLeft: '10px'}}
                                disabled={orderStore.loading}
                                content={'Подтвердить доставку заказа (RDBS)'}
                                onClick={() => orderStore.openChangeStatusConfirmation(OrderActionType.Receive)}
                        />
                    }
                </Segment>
            </Grid.Column>
        </Grid.Row>
        <Grid.Column width={3}>
            <InformationPanelItem listPlaceholder={orderStore.loading} shadows={false}>
                <Form>
                    <FormField>
                        <label>AvitoId</label>
                        <p>{order.avitoId}</p>
                    </FormField>
                    <FormField>
                        <label>Статус</label>
                        <Status status={order.avitoStatus}/>
                    </FormField>
                    <FormField>
                        <label>Магазин</label>
                        <a target="_blank" href={order.avitoAccount.profileUrl}>{order.avitoAccount.name}</a>
                    </FormField>
                    <FormField>
                        <label>Покупатель</label>
                        <a target="_blank" href={order.chat.client.profileUrl}>{order.chat.client.name}</a>
                    </FormField>
                    <FormField>
                        <label>Чат</label>
                        <a target="_blank"
                           href={`https://www.avito.ru/profile/messenger/channel/${order.chat.id}`}>{order.chat.id}</a>
                    </FormField>
                    <FormField>
                        <label>Дата создания</label>
                        <p>{utils.getLocalizedDateString(order.createdOn)}</p>
                    </FormField>
                    <FormField>
                        <label>Дата обновления</label>
                        <p>{utils.getLocalizedDateString(order.updatedOn)}</p>
                    </FormField>
                </Form>
            </InformationPanelItem>
        </Grid.Column>
        <Grid.Column width={11}>
            <Grid>
                <DebugInfoBlock debugInfo={orderStore.debugInfo} />
                <DeliveryInfo delivery={order.delivery}/>
                <Products order={order}/>
                <Logs items={order.logs}/>
            </Grid>
        </Grid.Column>

    </Grid>
}

const TrackingNumberDialog = observer(() => {
    return <Modal
        open={orderStore.trackNumberDialogOpened}
        size={'mini'}
    >
        <ModalHeader>Укажите трек номер</ModalHeader>
        <ModalContent>
            <Form>
                <FormInput
                    label='Трек номер'
                    type='text'
                    value={orderStore.trackNumber}
                    onChange={(_, data) => orderStore.setTrackNumber(data.value)}
                    error={orderStore.error}
                    loading={orderStore.loading}
                />
            </Form>
        </ModalContent>
        <ModalActions>
            <Button
                onClick={() => orderStore.closeTrackNumberDialog()}
                content={"Отмена"}
            />
            <Button
                content="Сохранить"
                onClick={() => orderStore.saveTrackNumber()}
                loading={orderStore.loading}
                positive
            />
        </ModalActions>
    </Modal>
});

const ChangeStatusConfirmationDialog = observer(() => {
    return <Confirm
        header='Изменение состояния заказа'
        content='Вы уверены?'
        cancelButton='Отмена'
        confirmButton="Подтвердить"
        open={orderStore.changeStatusConfirmationDialogOpened}
        onCancel={() => orderStore.closeChangeStatusConfirmationDialog()}
        onConfirm={() => orderStore.changeStatus()}
    />
});

const DeliveryInfo = observer((props: { delivery: Delivery }) => {
    const delivery = props.delivery;
    return <Grid.Row>
        <Grid.Column width={16}>
            <Header size={'small'}>Доставка</Header>
            <Segment>
                <Grid>
                    <Grid.Column width={8}>
                        <Form>
                            <FormField>
                                <label>ФИО покупателя</label>
                                <p>{delivery.buyerInfo?.fullName || '-'}</p>
                            </FormField>
                            <FormField>
                                <label>Номер телефона покупателя</label>
                                <p>{delivery.buyerInfo?.phoneNumber || '-'}</p>
                            </FormField>
                            {delivery.terminalInfo ?
                                <>
                                    <FormField>
                                        <label>Адрес пункта выдачи</label>
                                        <p>{delivery.terminalInfo.address || '-'}</p>
                                    </FormField>
                                    <FormField>
                                        <label>Код пункта выдачи</label>
                                        <p>{delivery.terminalInfo.code || '-'}</p>
                                    </FormField>
                                </> :
                                <>
                                    <FormField>
                                        <label>Адрес доставки</label>
                                        <p>{delivery.courierInfo?.address || '-'}</p>
                                    </FormField>
                                    <FormField>
                                        <label>Комментарий покупателя</label>
                                        <p>{delivery.courierInfo?.comment || '-'}</p>
                                    </FormField>
                                </>
                            }
                        </Form>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Form>
                            <FormField>
                                <label>Служба доставки</label>
                                <p>{delivery.serviceName || '-'}</p>
                            </FormField>
                            <FormField>
                                <label>Тип доставки</label>
                                <p>{getServiceTypeString(delivery.serviceType)}</p>
                            </FormField>
                            <FormField>
                                <label>Трек номер (номер отслеживания)</label>
                                <code>{delivery.trackingNumber || '-'}</code>
                            </FormField>
                            <FormField>
                                <label>Номер отправления</label>
                                <code>{delivery.dispatchNumber || '-'}</code>
                            </FormField>
                        </Form>
                    </Grid.Column>
                </Grid>
            </Segment>

        </Grid.Column>
    </Grid.Row>
});

function getServiceTypeString(serviceType?: DeliveryServiceType) {
    if (!serviceType) {
        return '-';
    }
    switch (serviceType) {
        case DeliveryServiceType.Pvz:
            return 'ПВЗ (PVZ)';
        case DeliveryServiceType.Dbs:
            return 'Доставка партнёрами продавца (ПВЗ) (DBS)';
        case DeliveryServiceType.Cdbs:
            return 'Доставка партнёрами продавца (курьер) (CDBS)';
        case DeliveryServiceType.Rdbs:
            return 'Доставка курьером продавца (RDBS)';
        case DeliveryServiceType.Courier:
            return 'Доставка курьером';
    }
}

const Products = observer((props: { order: OrderInfo }) => {
    const order = props.order;
    return <Grid.Row>
        <Grid.Column width={16}>
            <Header size={'small'}>Товары</Header>
            <Table celled compact>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell>Id</TableHeaderCell>
                        <TableHeaderCell>Наименование</TableHeaderCell>
                        <TableHeaderCell>Количество</TableHeaderCell>
                        <TableHeaderCell>Цена</TableHeaderCell>
                        <TableHeaderCell>Комиссия</TableHeaderCell>
                        <TableHeaderCell>Скидка</TableHeaderCell>
                        <TableHeaderCell>Итого</TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {order.items.map((item) => (
                        <TableRow key={item.id}>
                            <TableCell>{item.moySkladUrl ? <a target="_blank"
                                                              href={item.moySkladUrl}>{item.productId}</a> : item.productId}</TableCell>
                            <TableCell><a target="_blank" href={item.avitoUrl}>{item.title}</a></TableCell>
                            <TableCell>{item.count}</TableCell>
                            <TableCell>{item.price}</TableCell>
                            <TableCell>{item.commission}</TableCell>
                            <TableCell>{item.discount}</TableCell>
                            <TableCell>{item.total}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow textAlign={'right'}>
                        <TableHeaderCell colSpan={6}>
                            <Header size={'tiny'}>
                                Итого по заказу
                            </Header>
                        </TableHeaderCell>
                        <TableHeaderCell textAlign={'left'}>
                            {order.prices.total} ₽
                        </TableHeaderCell>
                    </TableRow>
                    <TableRow textAlign={'right'}>
                        <TableHeaderCell colSpan={6}>
                            <Header size={'tiny'}>
                                Стоимость доставки
                            </Header>
                        </TableHeaderCell>
                        <TableHeaderCell textAlign={'left'}>
                            {order.prices.delivery} ₽
                        </TableHeaderCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </Grid.Column>
    </Grid.Row>
});

const Logs = observer((props: { items: OrderIntegrationLog[] }) => {
    const items = props.items;
    return <Grid.Row>
        <Grid.Column width={16}>
            <Header size={'small'}>Журнал</Header>
            <Table celled compact>
                <TableBody>
                    {items.map((item) => (
                        <TableRow key={item.id}>
                            <TableCell>{utils.getLocalizedDateString(item.dateTime)}</TableCell>
                            <TableCell>{item.message}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Grid.Column>
    </Grid.Row>
});


export default observer(OrderPage);