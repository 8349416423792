import {AvitoAdDto, AvitoUserDto, AvitoFullMessageDto, AvitoMessageDto} from "../../Entities/Dtos";
import api from "../../Services/Api";
import {observable} from "mobx";

export default class ChatStorage {
    id: string;
    createdOn: Date;
    updatedOn: Date;
    @observable lastMessage: AvitoMessageDto;
    ad: AvitoAdDto;
    client: AvitoUserDto;
    @observable messages: AvitoFullMessageDto[] = [];
    @observable loading: boolean = false;
    @observable messageText: string = "";
    @observable isMessageSending: boolean = false;
    @observable notificationCount: number;

    constructor(id: string, createdOn: Date, updatedOn: Date, lastMessage: AvitoMessageDto, ad: AvitoAdDto, client: AvitoUserDto, notificationCount: number) {
        this.id = id;
        this.createdOn = createdOn;
        this.updatedOn = updatedOn;
        this.lastMessage = lastMessage;
        this.ad = ad;
        this.client = client;
        this.notificationCount = notificationCount;
    }

    async loadMessages() {
        this.changeLoading(true);
        const result = await api.get("messenger/message.list?chatId=" + this.id)
        if (result.success) {
            this.messages = result.data as AvitoFullMessageDto[];
            this.updateLastMessage(this.messages[this.messages.length - 1]);
            void this.read();
        }
        this.changeLoading(false);
    }

    async sendMessage() {
        if (this.messageText === '') {
            return;
        }
        
        if (this.isMessageSending) {
            return;
        }

        this.isMessageSending = true;

        const result = await api.post("messenger/message.send", {
            chatId: this.id,
            text: this.messageText
        });

        if (result.success) {
            this.setMessageText("");
            const message = result.data as AvitoFullMessageDto;
            this.messages.push(message);
            this.lastMessage = message;
        }

        this.isMessageSending = false;
    }

    async readInBackground() {
        if (this.notificationCount === 0) return;
        const result = await api.post("messenger/chat.readInBackground", {
            chatId: this.id
        });
    }
    
    async read() {
        if (this.notificationCount === 0) return;
        const result = await api.post("messenger/chat.read", {
            chatId: this.id
        });
        if (result.success) {
            this.clearNotificationCount();
        }
    }

    setMessageText(value: string) {
        this.messageText = value;
    }

    updateLastMessage(value: AvitoMessageDto) {
        this.lastMessage = value;
    }

    private changeLoading(value: boolean) {
        this.loading = value;
    }

    increaseNotificationCount() {
        this.notificationCount = this.notificationCount + 1;
    }

    clearNotificationCount() {
        this.notificationCount = 0;
    }
}
