import FieldName from "./FieldName";
import {Checkbox, Grid, Icon, Popup} from "semantic-ui-react";
import React from "react";
import {SemanticWIDTHS} from "semantic-ui-react/dist/commonjs/generic";

function FieldWithCheckbox(props: {
    value: boolean,
    tip?: string,
    onChange: (value: boolean) => void,
    name: string | React.ReactNode,
    children?: React.ReactNode,
    width?: SemanticWIDTHS,
    visible?: boolean
}) {
    if (props.visible !== undefined) {
        if (!props.visible) {
            return <></>;
        }
    }

    const {value, onChange, name, children, tip, width} = props;
    return <Grid.Row>
        <FieldName width={width}>
            <Checkbox checked={value}
                      onChange={(_, data) => onChange(data.checked!)}
                      label={name}/>
            {tip && <Popup
                content={tip}
                trigger={<Icon style={{marginLeft: '5px', cursor: 'pointer'}} name={"question circle outline"}/>}
            />}
        </FieldName>
        {children}
    </Grid.Row>
}

export default FieldWithCheckbox;
