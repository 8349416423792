import React, {useEffect} from "react";
import {observable} from "mobx";
import {observer} from "mobx-react-lite";
import api from "../../Services/Api";
import {CounterpartyDto} from "../../Entities/Dtos";
import FieldWithFieldIdSelector from "../Shared/FieldWithFieldIdSelector";

function CounterpartySelector(props: { onSelect: (id: string) => void, value?: string }) {
    useEffect(() => {
        void store.load();
    }, []);
    return <FieldWithFieldIdSelector name={"Контрагент"}
                                     fields={store.items}
                                     onChange={(value) => props.onSelect(value ?? "")}
                                     value={props.value}
                                     required
                                     tip={"Все заказы будут связаны с этим контрагентом"}
                                     onSearchChange={value => store.changeSearchQuery(value)}
    />
}

export default observer(CounterpartySelector);

class CounterpartyListStore {
    @observable items: CounterpartyDto[] = [];
    @observable searchQuery: string = "";
    @observable isLoading: boolean = false;

    async load() {
        this.isLoading = true;
        const result = await api.get("counterparty/list?name=" + this.searchQuery);
        if (result.success) {
            this.items = result.data;
        }
        this.isLoading = false;
    }

    changeSearchQuery(value: string) {
        this.searchQuery = value;
        void this.load();
    }
}

const store = new CounterpartyListStore();