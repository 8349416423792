import React from 'react';
import { Icon, Popup, Table } from 'semantic-ui-react';
import errorConverter from '../../../Services/ErrorConverter';
import './Style.css';

interface IRowProps {
    dateTime: string;
    message?: string;
    warningType: number;
    isSuccess?: boolean;
    inProgress?: boolean;
    productCount: number;
}

function LogDescription(props: {
    positive: undefined | boolean,
    loading: boolean | undefined,
    message: string | undefined,
    warningType: number
}) {
    if (props.warningType === 1 || props.warningType === 2) {
        return <Table.Cell>
            <div>
                <Icon name="warning sign" color={'yellow'}/>
                С 01.12.2024 г. Вступят в силу изменения в вашем тарифном плане. 
                <br></br>Будут введены лимиты на <b>общее количество объявлений в выгрузке</b>.
                <br></br>Пожалуйста, ознакомьтесь с новыми <a href={'https://unitxml.ru/#rec816168657'} target="_blank">условиями тарифа</a>.
            </div>
        </Table.Cell>
    }
    
    return <Table.Cell>
        {props.positive ?
            <div>
                <Icon loading={props.loading} name={props.loading ? "spinner" : "checkmark"}/>
            </div>
            :
            <Popup
                trigger={
                    <div style={{"cursor": "pointer"}}>
                        <Icon name="close"/>
                    </div>
                }
                content={errorConverter.getCaptionFormCode(props.message!)}
                position="right center"
            />
        }
    </Table.Cell>;
}

export function LogRow(props: IRowProps) {
    const {isSuccess, inProgress, productCount, warningType } = props;
    const positive = isSuccess || inProgress;
    let className = positive ? "positive-row" : "negative-row";
    if (props.warningType !== 0) {
        className = "warning-row";
    }
    
    return (
        <Table.Row className={className}>
            <Table.Cell>{props.dateTime ?? '-'}</Table.Cell>
            <LogDescription 
                positive={positive} 
                loading={inProgress} 
                message={props.message}
                warningType={warningType}
            />
            <Table.Cell>{productCount}</Table.Cell>
        </Table.Row>
    );
}