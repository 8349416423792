import {Embed} from "semantic-ui-react";
import React from "react";
import WelcomeMessage from "./WelcomeMessage";

export function WelcomeScreen() {
    return (
        <>
            <Embed
                style={{borderRadius: '5px'}}
                id='TP6FFOy_ml4'
                active={true}
                source='youtube'
            />
            <WelcomeMessage/>
        </>
    )
}