import api from "../Services/Api";
import {observable} from "mobx";

class EventTesterStore {
    @observable stockTransferEventId: string = "";
    @observable skladEntityChangedEventId: string = "";
    @observable visible: boolean = false;

    setStockTransferEventId(value: string) {
        this.stockTransferEventId = value;
    }
    
    setSkladEntityChangedEventId(value: string) {
        this.skladEntityChangedEventId = value;
    }

    async triggerStockTransferEventHandler() {
        await api.post('admin/stockTransferEventHandler.trigger', {
            id: this.stockTransferEventId
        });
    }

    async triggerSkladEntityChangedEventHandler() {
        await api.post('admin/skladEntityChangedEventHandler.trigger', {
            id: this.skladEntityChangedEventId
        });
    }

    show() {
        this.visible = true;
    }

    hide() {
        this.visible = false;
        this.stockTransferEventId = "";
        this.skladEntityChangedEventId = "";
    }
}

export default new EventTesterStore();