import React from 'react';
import ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css'
import './index.css';
import MainRouter from './MainRouter';
import {
    BrowserRouter as Router, Route, Switch
} from "react-router-dom";
import AdminRouter, {routeName as adminRoute} from "./Admin/AdminRouter";
import WidgetsRouter, {routeName as widgetsRoute} from "./Widgets/WidgetsRouter";

ReactDOM.render(
    <Router>
        <Switch>
            <Route path={"/" + adminRoute}>
                <AdminRouter/>
            </Route>
            <Route path={"/" + widgetsRoute}>
                <WidgetsRouter/>
            </Route>
            <Route>
                <MainRouter/>
            </Route>
        </Switch>
    </Router>,
    document.getElementById('root')
);