import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useParams} from "react-router-dom";
import dictionaryStore from "../../Stores/Dictioanaries/DictionaryStore";
import {InformationPanelItem} from "../Dashboard/InformationPanelItem";
import {Grid} from "semantic-ui-react";
import CreateDictionaryItem from "./CreateDictionaryItem";
import DictionaryItems from "./DictionaryItems";
import DictionaryItemsHeader from "./DictionaryItemsHeader";

function DictionaryPage() {
    const params = useParams<{ id: string }>();

    useEffect(() => {
        void dictionaryStore.init(params.id);
        return function cleanup() {
            dictionaryStore.cleanup();
        }
    }, [params.id])

    return <InformationPanelItem listPlaceholderLines={9}
                                 hideContentWhenLoading
                                 loading={dictionaryStore.isLoading && !dictionaryStore.isLoaded}>
        <Grid>
            <DictionaryItemsHeader
                visible={dictionaryStore.hasItems || dictionaryStore.creationDictionaryItem !== undefined}/>
            <DictionaryItems/>
            <CreateDictionaryItem/>
        </Grid>
    </InformationPanelItem>
}

export default observer(DictionaryPage);