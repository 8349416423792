import React from 'react'
import { Table } from 'semantic-ui-react'
import { LogRow } from './LogRow'
import './Style.css';
import { observer } from 'mobx-react-lite';
import store from "../../../Stores/ProfileInfoStore";

function LogTable() {
    return (
        <div className="log-div">
            <Table fixed celled className="log-table shadow" textAlign='center'>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={3} className="log-table-header-cell">Дата/Время</Table.HeaderCell>
                        <Table.HeaderCell className="log-table-header-cell">Статус</Table.HeaderCell>
                        <Table.HeaderCell width={4} className="log-table-header-cell">Общее количество объявлений</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {store.jobIsRunning && 
                        <LogRow dateTime={store.lastJobStartExecuting}
                                inProgress 
                                productCount={store.productCount}
                                warningType={0}
                        />
                    }
                    {store.integrations.map(
                        (integration) =>
                            <LogRow
                                dateTime={integration.date}
                                isSuccess={integration.isSuccess}
                                message={integration.message}
                                key={integration.id}
                                productCount={integration.productCount}
                                warningType={integration.warningType}
                            />)}
                </Table.Body>
            </Table>
        </div>
    )
}

export default observer(LogTable);