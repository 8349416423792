import React from 'react'
import { Grid } from 'semantic-ui-react';
import './Style.css';
import InformationPanel from './InformationPanel';
import IntegrationSettings from './IntegrationSettings';
import UserId from "./UserId";
import {observer} from "mobx-react-lite";
import PriceInfo from "./PriceInfo";
import Links from "./Links";
import skladStore from "../../Stores/SkladStore";

function Dashboard() {
    return (
        <>
            <Grid>
                <Grid.Column width='8'>
                    <InformationPanel />
                </Grid.Column>
                <Grid.Column width='5'>
                    {skladStore.showPriceInformation ? <PriceInfo /> :
                        <>
                            <IntegrationSettings/>
                            {skladStore.isLoaded && <UserId />}
                            <Links />
                        </>
                    }
                </Grid.Column>
            </Grid>
        </>
    )
}

export default observer(Dashboard);