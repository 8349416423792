import {observer} from "mobx-react-lite";
import authStore from "../../../Stores/AuthStore";
import {Button, Form, Grid, Header, Icon, TextArea} from "semantic-ui-react";
import orderStore, {DebugInfo} from "../../../Stores/OrderStore";
import React from "react";

export const DebugButton = observer(()=> {
    if (!authStore.isAdmin) {
        return <></>
    }
    return <>
        <Button loading={orderStore.debugInfo?.isLoading}
                   onClick={ () => orderStore.loadDebugInfo() }
                   basic>
        <Icon name={'bug'}/>Получить сырые данные
        </Button>
        <Button onClick={ () => orderStore.forceSendToMs() } basic>
            <Icon name={'bug'}/>Оправить заказ в МС
        </Button>
    </>
});

export const DebugInfoBlock = observer((props: {debugInfo?: DebugInfo}) => {
    const debugInfo = props.debugInfo;
    if (!debugInfo?.hasData) {
        return <></>
    }
    const avitoInfo = debugInfo.avitoInfo;
    const skladInfo = debugInfo.skladInfo;
    const loading = debugInfo.isLoading;
    return <Grid.Row>
        {avitoInfo && <Grid.Column width={8}>
            <Header size={'small'}>Сырые данные авито</Header>
            <Form loading={loading}>
                <TextArea style={{minHeight: 250}} value={avitoInfo} />
            </Form>
        </Grid.Column>}
        {skladInfo && <Grid.Column width={8}>
            <Header size={'small'}>Сырые данные МС</Header>
            <Form loading={loading}>
                <TextArea style={{minHeight: 250}} value={skladInfo} />
            </Form>
        </Grid.Column>}
    </Grid.Row>
});