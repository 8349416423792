import React, {useEffect, useState} from "react";
import api from "../../Services/Api";

function Links() {
    const [version, setVersion] = useState("3.5");
    useEffect(() => {
        async function loadAndSetVersion(){
            const result = await api.get("version");
            setVersion(result);
        }
        
        void loadAndSetVersion();
    }, []);
    
    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '5px'}}>
            <a color={'teal'} rel="noopener noreferrer" href={"https://wiki.unitxml.ru/ru/avito/start"}
               target="_blank">Документация</a>
            <a color={'teal'} rel="noopener noreferrer" href={"https://help.unitxml.ru/help/844394147"} target="_blank">Техническая
                поддержка</a>
            <p style={{opacity: '.45'}}>
                UnitXML v{version}
            </p>
        </div>
    )
}

export default Links;
