import {observer} from "mobx-react-lite";
import {useEffect} from "react";
import messengerStorage from "../../Stores/Messenger/MessengerStorage";
import React from "react";
import MessengerAccountComponent from "./MessengerAccountComponent";
import MessengerAccountList from "./MessengerAccountList";

function MessengerPage() {
    useEffect(() => {
        void messengerStorage.load();
    }, [])

    return <>
        <MessengerAccountList />
        {messengerStorage.selectedAccount && <MessengerAccountComponent account={messengerStorage.selectedAccount}/>}
    </>
}

export default observer(MessengerPage);