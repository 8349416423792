import { observable, reaction } from 'mobx';
import api from "../Services/Api";
import JwtUtils from "../Services/JwtUtils";

const jwt = 'jwt';

const authStore = observable({
    token: window.localStorage.getItem(jwt),
    login: "",
    password: "",
    get loggedIn() {
        return (this.token !== undefined && this.token !== null && this.token !== "");
    },
    get isAdmin(): boolean {
      if (this.loggedIn) {
          const parsedJwt = JwtUtils.parseJwt(this.token!);
          if (parsedJwt && parsedJwt.roles && parsedJwt.roles.includes("Admin")) {
              return true;
          }
      }
      return false;
    },
    logIn(token: string) {
        this.token = token;
    },
    async authByAdmin() {
        if (!this.login || !this.password) {
            return;
        }

        const result = await api.post("admin/auth", {
            login: this.login,
            password: this.password
        });
        if (result.success) {
            this.logIn(result.data);
        }
    },
    logOut() {
        this.token = null;
    }
});

reaction(
    () => authStore.token,
    token => {
        if (token) {
            window.localStorage.setItem(jwt, token);
        }
        else {
            window.localStorage.removeItem(jwt);
        }
    }
);

export default authStore;