import React from "react";
import {WelcomeScreen} from "../../Components/WelcomeScreen/WelcomeScreen";
import {Grid} from "semantic-ui-react";
import {InformationPanelItem} from "../Dashboard/InformationPanelItem";

export default function HelpPage() {
    return (
        <Grid>
            <Grid.Column width={'8'}>
                <InformationPanelItem header={'Помощь'}>
                    <WelcomeScreen />
                </InformationPanelItem>
            </Grid.Column>
        </Grid>
    )
}