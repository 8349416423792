import React from "react";
import Template from "../../../Entities/Template";
import {Button} from "semantic-ui-react";
import {observer} from "mobx-react-lite";

function TemplateSaveButton(props: {template: Template, fluid?: boolean}) {
    const {template, fluid} = props;
    return <Button
        onClick={() => template.save()}
        loading={template.isSaving}
        disabled={template.isSaving || !template.isValid || !template.isChanged}
        color='teal'
        floated={'right'}
        fluid={fluid}
    >
        Сохранить
    </Button>
}

export default observer(TemplateSaveButton);