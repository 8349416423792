import {DictionaryDto} from "../../Entities/Dtos";
import {observable} from "mobx";
import api from "../../Services/Api";

class DictionariesStore {
    @observable dictionaries: DictionaryDto[] = [];
    @observable loading: boolean = false;
    @observable isLoaded: boolean = false;
    @observable dictionaryToDelete?: DictionaryDto;
    @observable isDeleting: boolean = false;
    @observable creationDictionaryName?: string;
    @observable isCreationStarting: boolean = false;
    @observable isCreating: boolean = false;
    @observable creationNameHasChanges: boolean = false;
    @observable searchText?: string;
    @observable searchIsStarting: boolean = false;

    async load() {
        this.setLoading(true);
        try {
            const result = await api.get("dictionary/list");
            if (result.success) {
                this.dictionaries = result.data;
                this.setIsLoaded(true);
            }
        }
        finally {
            this.setLoading(false);
        }
    }
    
    markToDelete(dictionary: DictionaryDto) {
        this.dictionaryToDelete = dictionary;
    }

    stopDeleting() {
        this.dictionaryToDelete = undefined;
    }
    
    async delete(onSuccess: () => void) {
        if (!this.dictionaryToDelete) return;
        
        this.setIsDeleting(true);
        try {
            const result = await api.post("dictionary/delete", {
                id: this.dictionaryToDelete.id
            });
            if (result.success) {
                onSuccess();
                this.stopDeleting();
                await this.load();
            }
        }
        finally {
            this.setIsDeleting(false);
        }
    }
    
    startCreating() {
        this.isCreationStarting = true;
    }
    
    stopCreating() {
        this.isCreationStarting = false;
        this.creationDictionaryName = undefined;
        this.creationNameHasChanges = false;
    }
    
    isFitsFilter(dictionary: DictionaryDto): boolean {
        if (!this.searchText) return true;
        return dictionary.name.toLowerCase().includes(this.searchText);
    }
    
    async create() {
        if (!this.creationDictionaryName) return;

        this.setIsCreating(true);
        try {
            const result = await api.post("dictionary/create", {
                name: this.creationDictionaryName
            });
            if (result.success) {
                this.stopCreating();
                await this.load();
            }
        }
        finally {
            this.setIsCreating(false);
        }
    }
    
    changeCreationDictionaryName(value: string) {
        this.creationDictionaryName = value;
        this.creationNameHasChanges = true;
    }
    
    changeSearchText(value: string) {
        this.searchText = value.toLowerCase();
        this.searchIsStarting = true;
    }
    
    private setLoading(value: boolean) {
        this.loading = value;
    }

    private setIsLoaded(value: boolean) {
        this.isLoaded = value;
    }

    private setIsDeleting(value: boolean) {
        this.isDeleting = value;
    }

    private setIsCreating(value: boolean) {
        this.isCreating = value;
    }
    
    public cleanup() {
        this.searchIsStarting = false;
        this.searchText = undefined;
    }
}

const dictionariesStore = new DictionariesStore();
export default dictionariesStore;