import {observable} from "mobx";
import IHasIdAndName from "../Entities/IHasIdAndName";
import api from "../Services/Api";

class ProductStockChecker {
    @observable productId?: string;
    @observable productType?: string;
    @observable visible: boolean = true;
    @observable stock?: string;
    @observable isLoading: boolean = false;
    
    productTypes: IHasIdAndName[] = [
        {id: 'product', name: 'Продукт'},
        {id: 'variant', name: 'Модификация'},
        //{id: 'bundle', name: 'Комплект'}
    ]

    changeProductId(value: string) {
        this.productId = value;
    }

    changeProductType(value?: string) {
        this.productType = value;
    }
    
    get canCheck() {
        return this.productId && this.productType;
    }

    async check(avitoAccountId: string) {
        if (!this.canCheck) {
            return;
        }
        
        this.changeIsLoading(true);
        const result = await api.get('avitoAccounts/productStock?productId=' + this.productId + 
            '&avitoAccountId=' + avitoAccountId + 
            '&productType=' + this.productType);
        if (result.success) {
            this.changeStock(result.data);
        }
        this.changeIsLoading(false);
    }
    
    private changeIsLoading(value: boolean) {
        this.isLoading = value;
    }
    
    changeStock(value: string) {
        this.stock = value;
    }

    hide() {
        this.visible = false;
    }
    
    cleanup(): void {
        this.productId = undefined;
        this.stock = undefined;
        this.productType = undefined;
        this.isLoading = false;
        this.visible = true;
    }
}

const productChecker = new ProductStockChecker();
export default productChecker;