import React from "react";
import {observer} from "mobx-react-lite";
import {
    Button,
    Grid,
    Message,
    MessageContent,
    MessageHeader,
    Table,
    TableHeader,
    TableRow,
    TableHeaderCell,
    TableBody,
    TableCell,
    Dropdown
} from "semantic-ui-react";

import FieldWithFieldIdSelector from "../Shared/FieldWithFieldIdSelector";
import TextField from "../Shared/TextField";
import FieldWithCheckbox from "../Shared/FieldWithCheckbox";
import FieldValue from "../Shared/FieldValue";
import AvitoAccountSettingsStorage from "../../Stores/AvitoAccountSettingsStorage";
import {InformationPanelItem} from "../Dashboard/InformationPanelItem";
import unloadsSettingsStorage from "../../Stores/UnloadsSettingsStorage";
import OrderIntegrationSettingsStore from "../../Stores/OrderIntegrationSettingsStore";
import orderLookupStorage from "../../Stores/OrderLookupStorage";
import orderStatusConverter from "../../Services/OrderStatusConverter";
import {AvitoStatus} from "../../Entities/Order/AvitoStatus";
import profileInfoStore from "../../Stores/ProfileInfoStore";
import CounterpartySelector from "./CounterpartySelector";
import skladStore from "../../Stores/SkladStore";
import ProductStockChecker from "./ProductStockChecker";

function AvitoAccountComponent(props: { account: AvitoAccountSettingsStorage }) {
    const {account} = props;
    return <InformationPanelItem>
        <Grid>
            {!account.isNew && <TextField key={account.id + 'Name'} name={'Название'} value={account.name}
                                          onSave={() => account.save()}
                                          readOnly/>}
            <TextField key={account.id + 'ClientId'} name={'ClientId'} value={account.clientId}
                       onSave={() => account.save()}
                       required
                       autoFocus={account.isNew}
                       readOnly={!account.isNew}
                       helpUrl={'https://www.avito.ru/professionals/api'}
                       error={account.updatingTokenError !== undefined}
                       onChange={value => account.changeClientId(value)}/>
            <TextField key={account.id + 'ClientSecret'} name={'ClientSecret'} value={account.clientSecret}
                       onSave={() => account.save()}
                       required
                       readOnly={!account.isNew}
                       helpUrl={'https://www.avito.ru/professionals/api'}
                       type={'password'}
                       error={account.updatingTokenError !== undefined}
                       onChange={value => account.changeClientSecret(value)}/>
            {account.updatingTokenError && 
                <Grid.Row>
                    <Grid.Column>
                        <AvitoCredsInvalidTipMessage />
                    </Grid.Column>
                </Grid.Row>
            }
            <FieldWithFieldIdSelector name={'Файл выгрузки'} value={account.unloadId}
                                      fields={unloadsSettingsStorage.unloads}
                                      notClearable
                                      required
                                      placeholder={'Выберите XML файл с объявлениями, который будет связан с данным аккаунтом Авито'}
                                      tip={'XML файл с объявлениями, который будет связан с данным аккаунтом Авито'}
                                      onChange={value => account.changeUnload(value!)}/>
            <FieldWithFieldIdSelector name={'Поле для ссылок на объявления'}
                                      tip={'Поле, в которое будет проставляться ссылка на объявление'}
                                      value={account.adUrlFieldId}
                                      fields={skladStore.customFields}
                                      onChange={value => account.changeAdUrlFieldId(value)}
                                      visible={!account.isNew}
            />
            <FieldWithCheckbox width={9} name={'Активировать мессенджер'}
                               visible={!account.isNew}
                               value={account.messengerIsEnabled}
                               tip={'Ведение переписки с клиентами с авито из приложения.\n' +
                                   'Данный функционал позволяет полноценно вести диалоги с клиентами с авито напрямую из приложения.\n' +
                                   'Поддерживается несколько аккаунтов.'}
                               onChange={value => account.changeMessengerEnabled(value)}/>
            <FieldWithCheckbox name={'Получать заказы из Авито в МойСклад'}
                               visible={!account.isNew && profileInfoStore.showOrderIntegrationSettings}
                               value={account.orderIntegrationIsEnabled}
                               onChange={value => account.changeOrderIntegrationEnabled(value)}/>
            {account.orderIntegrationIsEnabled &&
                <OrderIntegrationSettings settings={account.orderIntegrationSettings}/>}
            <FieldWithCheckbox name={'Передавать изменение цены по API'}
                               visible={!account.isNew}
                               value={account.priceTransferIsEnabled}
                               tip={'Обновлять цену на Авито, при изменении цены в МойСклад по API'}
                               onChange={value => account.changePriceTransferEnabled(value)}
            />
            <FieldWithFieldIdSelector name={'Цена'}
                                      tip={'Цена, которая будет отправляться на Авито'}
                                      value={account.priceForTransferId}
                                      fields={skladStore.salePrices}
                                      onChange={value => account.changePriceForTransferId(value)}
                                      visible={account.priceTransferIsEnabled}
            />
            <FieldWithFieldIdSelector name={'Поле с номером объявления Авито'}
                                      tip={'Из данного поля будет браться номер объявления Авито'}
                                      value={account.avitoIdFieldId}
                                      fields={skladStore.customFields}
                                      onChange={value => account.changeAvitoIdFieldId(value)}
                                      visible={account.priceTransferIsEnabled || account.stockTransferIsEnabled}
            />
            <FieldWithCheckbox width={7} name={'Передавать изменение остатков по API'}
                               visible={!account.isNew}
                               value={account.stockTransferIsEnabled}
                               tip={'Обновлять остаток на Авито, при изменении остатка в МойСклад по API'}
                               onChange={value => account.changeStockTransferEnabled(value)}>
                <FieldValue width={9} visible={account.stockTransferIsEnabled}>
                    <StockApiTipMessage/>
                </FieldValue>
            </FieldWithCheckbox>
            <ProductStockChecker avitoAccountId={account.id} />
            <FieldWithCheckbox name={<AgreementCheckboxLabel/>}
                               value={account.agreementIsAccepted}
                               visible={account.isNew}
                               onChange={value => account.changeAgreementAccepted(value)}/>
            <Grid.Row>
                <Grid.Column floated={'right'}>
                    <Button color={'teal'} floated={'right'}
                            onClick={() => account.save()}
                            disabled={!account.canSave}
                            loading={account.saving}>Сохранить</Button>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </InformationPanelItem>
}

const StatesMapping = observer(function (props: { settings: OrderIntegrationSettingsStore }) {
    const settings = props.settings;
    return <Grid.Column width={16}>
        <Table compact celled>
            <TableHeader>
                <TableRow>
                    <TableHeaderCell>Статус Авито</TableHeaderCell>
                    <TableHeaderCell>Статус в МойСклад</TableHeaderCell>
                </TableRow>
            </TableHeader>
            <TableBody>
                {
                    settings.statesMapping &&
                    Object.keys(settings.statesMapping).map((status) => (
                        <TableRow key={status}>
                            <TableCell>{orderStatusConverter.getStatusName(status as AvitoStatus)}</TableCell>
                            <TableCell>
                                <Dropdown fluid
                                          placeholder={'Выберите статус в МойСклад'}
                                          value={settings.statesMapping[status as AvitoStatus]?.id || ''}
                                          clearable={true}
                                          onChange={(_, e) => settings.setMoySkladStatusForAvitoStatus(status as AvitoStatus, e.value as string)}
                                          options={orderLookupStorage.orderStates.map(field => {
                                              return {
                                                  key: field.id,
                                                  value: field.id,
                                                  text: field.name
                                              }
                                          })}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
            </TableBody>
        </Table>
    </Grid.Column>;
});

const OrderIntegrationSettings = observer(function (props: { settings: OrderIntegrationSettingsStore }) {
    const {settings} = props;
    return <InformationPanelItem header={'Настройка интеграции заказов'} shadows={false}>
        <Grid>
            <FieldWithFieldIdSelector name={'Юр. лицо'} value={settings.organization?.id}
                                      fields={orderLookupStorage.organizations}
                                      notClearable
                                      required
                                      placeholder={'Выберите юр.лицо'}
                                      onChange={value => settings.setOrganization(value!)}/>
            <FieldWithFieldIdSelector name={'Канал продаж'} value={settings.salesChannel?.id}
                                      fields={orderLookupStorage.salesChannels}
                                      placeholder={'Выберите канал продаж'}
                                      onChange={value => settings.setSalesChannel(value!)}/>
            <FieldWithFieldIdSelector name={'Отдел'} value={settings.department?.id}
                                      fields={orderLookupStorage.departments}
                                      placeholder={'Выберите отдел'}
                                      onChange={value => settings.setDepartment(value!)}/>
            <FieldWithFieldIdSelector name={'Поле для трэкномера'} value={settings.trackNumberField?.id}
                                      fields={orderLookupStorage.orderFields}
                                      placeholder={'Выберите поле'}
                                      onChange={value => settings.setTrackNumberField(value!)}/>
            <FieldWithFieldIdSelector name={'Поле для номера отправления'} value={settings.dispatchNumberField?.id}
                                      fields={orderLookupStorage.orderFields}
                                      placeholder={'Выберите поле'}
                                      onChange={value => settings.setDispatchNumberField(value!)}/>
            <FieldWithFieldIdSelector name={'Поле для названия службы доставки'} value={settings.deliveryServiceNameField?.id}
                                      fields={orderLookupStorage.orderFields}
                                      placeholder={'Выберите поле'}
                                      onChange={value => settings.setDeliveryServiceNameField(value!)}/>
            <FieldWithFieldIdSelector name={'Поле для адреса доставки'} value={settings.deliveryAddressField?.id}
                                      fields={orderLookupStorage.orderFields}
                                      placeholder={'Выберите поле'}
                                      onChange={value => settings.setDeliveryAddressField(value!)}/>
            <FieldWithFieldIdSelector name={'Поле для ФИО покупателя'} value={settings.buyerFullNameField?.id}
                                      fields={orderLookupStorage.orderFields}
                                      placeholder={'Выберите поле'}
                                      onChange={value => settings.setBuyerFullNameFieldField(value!)}/>
            <FieldWithFieldIdSelector name={'Поле для телефона покупателя'} value={settings.buyerPhoneNumberField?.id}
                                      fields={orderLookupStorage.orderFields}
                                      placeholder={'Выберите поле'}
                                      onChange={value => settings.setBuyerPhoneNumberFieldField(value!)}/>
            <FieldWithCheckbox name={'Использовать нумерацию заказов МойСклад'}
                               value={settings.useMsNumeration}
                               tip={'Если выбрана данная опция, то при создании заказа в МойСклад, приложение будет использовать стандартную нумерацию заказов. При этом номер заказа с Авито будет вставлен в дополнительное поле в заказе. Выберите дополнительное поле, в которое будет вставлен номер заказа с Авито. Если опция отключена, то при создании заказа в МойСклад, в качестве номера заказа будет использован номер заказа с Авито.'}
                               onChange={value => settings.setUseMsNumeration(value)}
                               width={7}
            />
            <FieldWithFieldIdSelector name={'Поле для номера'} value={settings.numberField?.id}
                                      fields={orderLookupStorage.orderFields}
                                      placeholder={'Выберите поле'}
                                      required
                                      tip={'В данное поле будет подставляться номер заказа Авито'}
                                      visible={settings.useMsNumeration}
                                      onChange={value => settings.setNumberField(value!)}/>
            <FieldWithCheckbox value={settings.needCreateCounterparty}
                               onChange={(value) => settings.setNeedCreateCounterparty(value)}
                               name={"Создавать контрагентов"}/>
            {settings.needCreateCounterparty ? 
                <>
                    <FieldWithFieldIdSelector required
                                              name={'Тип контрагента'}
                                              value={settings.companyType?.id}
                                              fields={orderLookupStorage.companyTypes}
                                              placeholder={'Выберите тип'}
                                              onChange={value => settings.setCompanyType(value!)}
                    />
                    <TextField
                        name={'Группа пользователей'}
                        value={settings.group ?? ""}
                        onChange={value => settings.setGroup(value)}
                        readOnly={false}
                    />
                </>:
                <CounterpartySelector 
                    value={settings.counterpartyId} 
                    onSelect={(id) => settings.setCounterpartyId(id)}
                />}
            <Grid.Row>
                <StatesMapping settings={settings}/>
            </Grid.Row>
            <FieldWithCheckbox name={'Вставлять в заказ комплектующие комплекта'}
                               tip={'При поступлении заказа с Авито на товар, который является в МойСклад комплектом, при ВКЛЮЧЕННОЙ опции, в заказ будут вставлены все комплектующие данного комплекта. При ВЫКЛЮЧЕННОЙ опции, в заказ подставится комплект'}
                               value={settings.unpackBundles}
                               width={10}
                               onChange={value => settings.setUnpackBundles(value)}/>
            <FieldWithFieldIdSelector name={'Поле цены для расценки комплекта'} 
                                      value={settings.componentPriceForUnpackBundle?.id}
                                      fields={skladStore.salePrices}
                                      visible={settings.unpackBundles}
                                      placeholder={'Выберите поле'}
                                      tip={'Данное поле будет браться из карточки товара и подставляться в цену позиций заказа'}
                                      onChange={value => settings.setComponentPriceForUnpackBundle(value!)}/>
            <FieldWithCheckbox name={'Резервировать позиции заказа'}
                               tip={'При поступлении заказа с Авито на товар, который в заказе, будет установлен резерв на складе'}
                               value={settings.reservePositions}
                               width={10}
                               onChange={value => settings.setReservePositions(value)}/>
            <FieldWithCheckbox name={'Вычитать из цены заказа комиссию Авито'}
                               tip={'Если выбрана данная опция, то в заказ будет вставлена цена на товар за вычетом комиссии Авито'}
                               value={settings.removeCommissionFromPrice}
                               width={10}
                               onChange={value => settings.setRemoveCommissionFromPrice(value)}/>
            <FieldWithCheckbox name={'Вычитать из цены заказа скидки Авито'}
                               tip={'Если выбрана данная опция, то в заказ будет вставлена цена на товар за вычетом скидок Авито'}
                               value={settings.removeDiscountFromPrice}
                               width={10}
                               onChange={value => settings.setRemoveDiscountFromPrice(value)}/>
            <TextField name={"НДС"}
                       nameWidth={3}
                       valueWidth={4}
                       value={settings.vat} 
                       onChangeNumber={value => settings.setVat(value)}
                       label={{basic: true, content: '%' }}
                       labelPosition='right'
                       type={"number"}/>
        </Grid>
    </InformationPanelItem>
})

const AvitoCredsInvalidTipMessage = () => <Message negative>
    <MessageHeader>Внимание!</MessageHeader>
    <MessageContent>
        Данные для входа в Avito стали невалидны. Просим обновить их через нашу тех поддержку, либо пересоздать аккаунт
    </MessageContent>
</Message>

const StockApiTipMessage = () => <Message negative>
    <MessageHeader>Внимание!</MessageHeader>
    <MessageContent>
        <p>Данный способ работает только с включенной опцией "Использовать
            ускоренную проверку передачи остатков"
            Остаток будет формироваться на основе выбранного фильтра:
            - Доступно
            - Остаток за вычетом резерва
            - Физический остаток
            Настройте данные фильтры на главной странице приложения</p>
        <p>Уже должно быть настроена передача остатков на Авито с помощью отдельного XML файла <a
            target="_blank"
            href={'https://wiki.unitxml.ru/avito/start#передача-остатков-товара-на-авито'}>Инструкция
            по настройке</a></p>
        <p>Если вам необходимо передавать нулевое значение остатка для товаров, то не используйте
            данный способ, используйте пока доступный ранее способ передачи остатков через отдельный
            XML файл</p>
    </MessageContent>
</Message>;

const AgreementCheckboxLabel = () => <label>Даю согласие на использование согласно<a
    onClick={(e) => e.stopPropagation()}
    href='https://wiki.unitxml.ru/avito/start'
    target={'_blank'}> условиям</a></label>;

export default observer(AvitoAccountComponent);
