import React, {useEffect} from 'react';
import {observer} from "mobx-react-lite";
import {Grid} from "semantic-ui-react";
import storesSettingsStorage from "../../Stores/StoresSettingsStorage";
import StoresList from "./StoresList";
import StoreComponent from "./StoreComponent";
import skladStore from "../../Stores/SkladStore";
import StoresNotActiveWarning from "../Shared/StoresNotActiveWarning";

function StoresSettingsPage() {
    useEffect(() => {
        void storesSettingsStorage.load();
    }, []);


    return <Grid>
        <Grid.Column width={3}>
            {!skladStore.needCheckStock && <StoresNotActiveWarning/>}
            <StoresList/>
        </Grid.Column>
        <Grid.Column width={10}>
            {storesSettingsStorage.currentStore && <StoreComponent store={storesSettingsStorage.currentStore}/>}
        </Grid.Column>
    </Grid>
}

export default observer(StoresSettingsPage);
