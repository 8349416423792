import {observable} from "mobx";

export default class FieldValue {
    id?: string;
    fieldId: string;
    @observable value: string;
    @observable hasChanges: boolean = false;

    constructor(fieldId: string, id?: string, value?: string) {
        this.id = id;
        this.value = value ?? '';
        this.fieldId = fieldId;
    }

    changeValue(value: string) {
        this.value = value;
        this.hasChanges = true;
    }
}
