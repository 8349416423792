import React from 'react'
import {Button, Grid, List, Popup} from 'semantic-ui-react';
import './Style.css';
import {observer} from "mobx-react-lite";
import skladStore from "../../../../Stores/SkladStore";

function UpdateButton() {
    const button = <Button basic as={'a'}
                           primary compact
                           loading={skladStore.dataUpdateIsRunning}
                           disabled={skladStore.dataUpdateIsRunning}
                           onClick={() => skladStore.updateUserData()}
    >Обновить данные</Button>;
    return (
        <Grid centered padded={'vertically'}>
                <Grid.Column verticalAlign={'middle'}>
                    <Popup
                        position='right center'
                        trigger={button}
                    >
                        <Popup.Header>Обновятся</Popup.Header>
                        <Popup.Content>
                            <List bulleted>
                                <List.Item>кастомные поля</List.Item>
                                <List.Item>характеристики модификаций</List.Item>
                                <List.Item>категории товаров</List.Item>
                                <List.Item>цены продажи</List.Item>
                            </List>
                        </Popup.Content>
                    </Popup>
                </Grid.Column>
        </Grid>
    );
}

export default observer(UpdateButton);