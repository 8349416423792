import {observable} from "mobx";
import api from "../Services/Api";
import avitoAccountsSettingsStorage from "./AvitoAccountsSettingsStorage";
import OrderIntegrationSettingsStore from "./OrderIntegrationSettingsStore";
import {OrderIntegrationSettingsDto} from "../Entities/Dtos";

export default class AvitoAccountSettingsStorage {
    id: string;
    isNew: boolean = false;
    @observable name: string;
    @observable clientId: string;
    @observable clientSecret: string;
    updatingTokenError?: string;
    @observable unloadId: string;
    @observable hasChanges: boolean = false;
    @observable saving: boolean = false;
    @observable deleting: boolean = false;
    @observable messengerIsEnabled: boolean;
    @observable orderIntegrationIsEnabled: boolean;
    @observable orderIntegrationSettings: OrderIntegrationSettingsStore;
    @observable agreementIsAccepted: boolean = false;
    @observable stockTransferIsEnabled: boolean;
    @observable priceTransferIsEnabled: boolean;
    @observable adUrlFieldId?: string;
    @observable priceForTransferId?: string;
    @observable avitoIdFieldId?: string;
    
    constructor(name: string, id?: string, clientId?: string, 
                clientSecret?: string, updatingTokenError?: string, unloadId?: string, 
                messengerIsEnabled?: boolean, orderIntegrationIsEnabled?: boolean, 
                orderIntegrationSettings?: OrderIntegrationSettingsDto, 
                stockTransferIsEnabled?: boolean,
                priceForTransferId?: string,
                adUrlFieldId?: string,
                avitoIdFieldId?: string) {
        this.isNew = !id;
        this.id = id ?? 'new';
        this.name = name;
        this.clientId = clientId ?? '';
        this.clientSecret = clientSecret ?? '';
        this.updatingTokenError = updatingTokenError;
        this.unloadId = unloadId ?? '';
        this.messengerIsEnabled = messengerIsEnabled ?? false;
        this.orderIntegrationIsEnabled = orderIntegrationIsEnabled ?? false;
        this.orderIntegrationSettings = orderIntegrationSettings !== undefined ? 
            new OrderIntegrationSettingsStore(
                ()=> {
                    this.hasChanges = true;
                },
                false,
                orderIntegrationSettings.organization,
                orderIntegrationSettings.salesChannel,
                orderIntegrationSettings.department,
                orderIntegrationSettings.group,
                orderIntegrationSettings.companyType,
                orderIntegrationSettings.counterparty,
                orderIntegrationSettings.numberField,
                orderIntegrationSettings.trackNumberField,
                orderIntegrationSettings.dispatchNumberField,
                orderIntegrationSettings.deliveryServiceNameField,
                orderIntegrationSettings.deliveryAddressField,
                orderIntegrationSettings.buyerFullNameField,
                orderIntegrationSettings.buyerPhoneNumberField,
                orderIntegrationSettings.statesMapping,
                orderIntegrationSettings.componentPriceForUnpackBundle,
                orderIntegrationSettings.reservePositions,
                orderIntegrationSettings.removeCommissionFromPrice,
                orderIntegrationSettings.removeDiscountFromPrice,
                orderIntegrationSettings.vat) :
            new OrderIntegrationSettingsStore(()=> { this.hasChanges = true; }, true);
        this.stockTransferIsEnabled = stockTransferIsEnabled ?? false;
        this.priceForTransferId = priceForTransferId;
        this.priceTransferIsEnabled = priceForTransferId !== undefined;
        this.adUrlFieldId = adUrlFieldId;
        this.avitoIdFieldId = avitoIdFieldId;
    }

    async save() {
        if (!this.canSave || this.saving) {
            return;
        }

        this.changeSaving(true);
        try {
            const result = await api.post(this.getSaveUrl, {
                id: this.id,
                unloadId: this.unloadId,
                name: this.name,
                clientId: this.clientId,
                clientSecret: this.clientSecret,
                messengerIsEnabled: this.messengerIsEnabled,
                orderIntegrationIsEnabled: this.orderIntegrationIsEnabled,
                orderIntegrationSettings: this.orderIntegrationSettings.serialize(),
                stockTransferIsEnabled: this.stockTransferIsEnabled,
                adUrlFieldId: this.adUrlFieldId ? this.adUrlFieldId : null,
                priceForTransferId: this.priceForTransferId ? this.priceForTransferId : null,
                avitoIdFieldId: this.avitoIdFieldId ? this.avitoIdFieldId : null
            });
            if (result.success) {
                await avitoAccountsSettingsStorage.load();
            }
        } finally {
            this.changeSaving(false);
        }
    }

    private get getSaveUrl(): string {
        if (this.isNew) {
            return 'avitoAccounts/create';
        }

        return 'avitoAccounts/edit';
    }

    async delete() {
        this.deleting = true;
        try {
            const result = await api.post("avitoAccounts/delete", {
                id: this.id
            })

            if (result.success) {
                await avitoAccountsSettingsStorage.load();
            }
        } finally {
            this.deleting = false;
        }
    }

    get canSave() {
        if (this.priceTransferIsEnabled && !this.priceForTransferId) {
            return false;
        }
        
        const orderSettingsCanSave = !this.orderIntegrationIsEnabled || this.orderIntegrationSettings.canSave;
        return this.hasChanges && this.name && this.clientId && this.clientSecret && this.unloadId && (!this.isNew || this.agreementIsAccepted) && orderSettingsCanSave;
    }

    changeUnload(unloadId: string) {
        this.unloadId = unloadId;
        this.hasChanges = true;
    }

    changeMessengerEnabled(value: boolean) {
        this.messengerIsEnabled = value;
        this.hasChanges = true;
    }

    changeStockTransferEnabled(value: boolean) {
        this.stockTransferIsEnabled = value;
        this.hasChanges = true;
    }

    changePriceTransferEnabled(value: boolean) {
        if (!value) {
            this.priceForTransferId = undefined;
        }
        
        this.priceTransferIsEnabled = value;
        this.hasChanges = true;
    }

    changeClientId(value: string) {
        if (!this.isNew) {
            return;
        }
        this.clientId = value;
        this.hasChanges = true;
    }

    changeClientSecret(value: string) {
        if (!this.isNew) {
            return;
        }
        this.clientSecret = value;
        this.hasChanges = true;
    }
    
    changeAgreementAccepted(value: boolean) {
        this.agreementIsAccepted = value;
    }

    private changeSaving(value: boolean) {
        this.saving = value;
    }

    changeOrderIntegrationEnabled(value: boolean) {
        this.orderIntegrationIsEnabled = value;
        this.hasChanges = true;
    }
    
    changeAdUrlFieldId(value?: string) {
        this.adUrlFieldId = value;
        this.hasChanges = true;
    }

    changePriceForTransferId(value?: string) {
        this.priceForTransferId = value;
        this.hasChanges = true;
    }
    
    changeAvitoIdFieldId(value?: string) {
        this.avitoIdFieldId = value;
        this.hasChanges = true;
    }
}
