import {Dropdown, Header} from "semantic-ui-react";
import React from 'react';
import {observer} from "mobx-react-lite";
import skladStore from "../../../Stores/SkladStore";

function FieldSelector(props: { label: string, value: string | null, onSelect: (value: string | null) => void }) {
    const {label, value, onSelect} = props;
    return (
        <>
            <Header as='h5' textAlign='center'>
                {label}
            </Header>
            <Dropdown
                className='shadow'
                fluid
                selection
                name='default'
                options={skladStore.customFields.map((f) => {
                    return {text: f.name, value: f.id};
                })}
                value={value || ''}
                onChange={(_, data) =>
                    onSelect(data.value ? (data.value as string) : null)}
                placeholder='Выберите поле'
                clearable/>
        </>
    );
}

export default observer(FieldSelector);