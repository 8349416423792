import React from "react";
import {observer} from "mobx-react-lite";
import {Button} from "semantic-ui-react";
import dictionariesStore from "../../Stores/Dictioanaries/DictionariesStore";
import EditorInput from "../Shared/EditorInput";

function CreateDictionary() {
    return <>
        {dictionariesStore.isCreationStarting
            ?
            <EditorInput value={dictionariesStore.creationDictionaryName}
                         onEdit={value => dictionariesStore.changeCreationDictionaryName(value)}
                         cancel={() => dictionariesStore.stopCreating()}
                         save={() => void dictionariesStore.create()}
                         canSave={dictionariesStore.creationDictionaryName !== undefined && dictionariesStore.creationNameHasChanges}
                         isSaving={dictionariesStore.isCreating}
            />
            :
            <Button fluid color={'teal'}
                    content={'Добавить'}
                    disabled={dictionariesStore.loading && !dictionariesStore.isLoaded}
                    onClick={() => dictionariesStore.startCreating()}
            />
        }
    </>
}

export default observer(CreateDictionary);