import {observable} from "mobx";
import api from "../../Services/Api";

export default class DictionaryItem {
    @observable key: string;
    @observable value: string;
    @observable initValue: string;
    dictionaryId?: string;
    @observable isSaving: boolean = false;
    onSaved: () => void;
    @observable isEditing: boolean = false;
    @observable isDeleting: boolean = false;

    constructor(key: string, value: string, onSaved: () => void, dictionaryId?: string) {
        this.dictionaryId = dictionaryId;
        this.key = key;
        this.value = value;
        this.initValue = value;
        this.onSaved = onSaved;
    }

    async save() {
        if (!this.canSave) return;
        this.setIsSaving(true);
        try {
            const result = await api.post("dictionary/addOrUpdateItem", {
                dictionaryId: this.dictionaryId,
                key: this.key,
                value: this.value
            });
            if (result.success) {
                this.onSaved()
            }
        } finally {
            this.setIsSaving(false);
        }
    }
    
    async delete() {
        this.setIsDeleting(true);
        try {
            const result = await api.post("dictionary/deleteItem", {
                dictionaryId: this.dictionaryId,
                key: this.key
            });
            if (result.success) {
                this.onSaved()
            }
        } finally {
            this.setIsDeleting(false);
        }
    }
    
    startEditing() {
        this.isEditing = true;
    }

    stopEditing() {
        this.isEditing = false;
    }
    
    cancelEditing() {
        this.stopEditing();
        this.updateValue(this.initValue);
    }

    get canSave() {
        return this.value !== '' && this.key !== '' && this.value !== this.initValue;
    }

    updateValue(value: string) {
        this.value = value;
    }

    updateKey(value: string) {
        this.key = value;
    }

    private setIsSaving(value: boolean) {
        this.isSaving = value;
    }

    private setIsDeleting(value: boolean) {
        this.isDeleting = value;
    }
}