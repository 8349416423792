import { observer } from 'mobx-react-lite'
import React from 'react'
import { Button } from 'semantic-ui-react'
import './Style.css'
import skladStore from "../../../../Stores/SkladStore";

function SaveButton() {
    return (
        <Button fluid color='teal' onClick={() => skladStore.save()} disabled={!skladStore.hasChanges} loading={skladStore.isSaving}>Сохранить настройки</Button>
    )
}

export default observer(SaveButton);