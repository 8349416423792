import {observer} from 'mobx-react-lite'
import React from 'react'
import {Dropdown} from 'semantic-ui-react';

interface IProps {
    values: string[];
    onChecked: (value: string) => void;
    fixHeight?: boolean;
    showMultiplyModeEditor: boolean;
    multiplyModeEnabled: boolean;
    onSwitchMultiplyMode: () => void;
}

function FieldValueSelector(props: IProps) {
    return (
        <Dropdown
            icon='angle down'
            button
            className={props.fixHeight ? 'icon fix-height' : 'icon'}
            direction='right'
            style={{marginRight: '1px'}}
        >
            <Dropdown.Menu>
                <Dropdown.Header content='Стандартные значения авито'/>
                <Dropdown.Menu scrolling style={{maxHeight: '150px'}}>
                    {props.values.map((value, index) => (
                        <Dropdown.Item key={index} text={value} onClick={() => props.onChecked(value)}/>
                    ))}
                </Dropdown.Menu>
                {props.showMultiplyModeEditor &&
                    <>
                        <Dropdown.Divider/>
                        <Dropdown.Item onClick={() => props.onSwitchMultiplyMode()}
                                       text={props.multiplyModeEnabled ? 'Выключить мультивыбор' : 'Включить мультивыбор'}
                        />
                    </>}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default observer(FieldValueSelector);
