import api from "../Services/Api";
import {observable} from "mobx";

class TokenConverterStore {
    @observable inputValue: string = "";
    @observable outputValue: string = "";
    @observable visible: boolean = false;

    setInputValue(value: string) {
        this.inputValue = value;
    }

    async encode() {
        this.outputValue = await api.get('admin/token.encode?token=' + this.inputValue);
    }

    async decode() {
        this.outputValue = await api.get('admin/token.decode?token=' + this.inputValue);
    }

    show() {
        this.visible = true;
    }

    hide() {
        this.visible = false;
        this.inputValue = "";
        this.outputValue = "";
    }
}

export default new TokenConverterStore();