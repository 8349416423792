const errorConverter = {
    getCaptionFormCode(code: string) {
        return errors[code] ? errors[code] : code
    }
};

const errors: { [code: string]: string } = {
    'user_already_exists': "Пользователь с таким Email уже существует",
    'invalid_password': "Неверный логин/пароль",
    'user_not_found': 'Пользователь не найден',
    'invalid_token': 'Неверный токен',
    'empty_userid': 'Пустой UserId',
    'cant_get_data_from_sklad': "Не удалось экспортировать данные из МойСклад",
    'user_not_confirmed': "Email не подтверждён",
    'not_found_category': 'Категория не найдена',
    'not_found_templates': 'Шаблоны не найдены',
    'template_not_found': 'Шаблон не найден',
    'no_products': 'Нет товаров для выгрузки',
    'template_has_product_folders': 'У данного шаблона есть связанные категории',
    'template_fields_not_uniq': 'У данного шаблона найдены НЕуникальные поля, пожалуйста обновите страницу',
    'task_already_run': 'Выгрузка уже запущена',
    'load_is_too_match': 'Высокая нагрузка, попробуйте позже',
    'stop_manually': 'Остановлено вручную',
    'empty_period': 'Пустой период выгрузки',
    'AvitoWrongCredential': 'Неверные данные авторизации Авито',
    'UnloadMustHaveUniqName': 'Имя файла должно быть уникальным',
    DEFAULT: "Что-то пошло не так :("
}

export default errorConverter;