import { observer } from 'mobx-react-lite';
import React from 'react'
import { Grid, Icon, Input } from 'semantic-ui-react';
import {ProductFolder} from "../../Entities/ProductFolder";

interface IProps {
    item: ProductFolder
}

function TemplateMappingItem(props: IProps) {
    const { item } = props;
    return (
        <Grid.Row verticalAlign='middle'>
            <Grid.Column width={9}>
                <Input fluid value={item.name} readOnly={true} />
            </Grid.Column>
            <Grid.Column width={6} textAlign='left' verticalAlign='middle'>
                <Input fluid value={item.templateName} readOnly={true} />
            </Grid.Column>
            <Grid.Column verticalAlign={'middle'} width={1}>
                <Icon fitted name='close' style={{ 'cursor': 'pointer', 'color': '#b91461' }} onClick={() => item.removeTemplate()} />
            </Grid.Column>
        </Grid.Row>
    )
}

export default observer(TemplateMappingItem);
