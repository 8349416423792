import {Button, Dropdown, Grid, Popup} from "semantic-ui-react";
import React from "react";
import {observer} from "mobx-react-lite";
import FieldName from "./FieldName";
import FieldValue from "./FieldValue";
import IHasIdAndName from "../../Entities/IHasIdAndName";

function FieldWithFieldIdSelector(props: {
    name: string,
    value?: string,
    fields: IHasIdAndName[],
    onChange(value?: string): void,
    onSearchChange?(value: string): void,
    notClearable?: boolean
    required?: boolean,
    tip?: string,
    placeholder?: string,
    visible?: boolean
}) {
    const {
        name, value, fields, onChange,
        notClearable, required,
        tip, placeholder,
        onSearchChange
    } = props;

    if (props.visible !== undefined) {
        if (!props.visible) {
            return <></>;
        }
    }

    return <Grid.Row>
        <FieldName name={name} required={required}/>
        <FieldValue style={tip && {display: 'flex'}}>
            <Dropdown fluid
                      placeholder={placeholder ?? 'Выберите поле'}
                      value={value || ''}
                      selection
                      search={onSearchChange !== undefined}
                      onSearchChange={(_, data) => onSearchChange ? onSearchChange(data.searchQuery as string) : {}}
                      clearable={!notClearable}
                      onChange={(_, e) => onChange(e.value as string)}
                      options={fields.map(field => {
                          return {
                              key: field.id,
                              value: field.id,
                              text: field.name
                          }
                      })}
                      style={tip && {borderTopRightRadius: '0', borderBottomRightRadius: '0'}}
            />
            {tip && <Popup
                position={'bottom right'}
                content={tip}
                trigger={<Button style={{borderTopLeftRadius: '0', borderBottomLeftRadius: '0', marginRight: '0'}}
                                 icon='help'/>}/>}
        </FieldValue>
    </Grid.Row>
}

export default observer(FieldWithFieldIdSelector);
