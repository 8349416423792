import { observer } from 'mobx-react-lite'
import React from 'react'
import { Header, Icon, Segment, TransitionablePortal } from 'semantic-ui-react'
import errorStore from "../../Stores/ErrorStore";

function ErrorPortal() {
    return (
        <TransitionablePortal onClose={errorStore.close} open={errorStore.isOpen}>
            <Segment color='red'
                style={{ left: '83%', position: 'fixed', top: '0%', zIndex: 1000 }}
            >
                <Header>Ошибка!<Icon onClick={errorStore.close} name='close' size='small' fitted style={{ 'float': 'right', 'fontSize': '0.8em', 'cursor': 'pointer' }} /></Header>
                {errorStore.errors.map((error, index) => <p key={index}>{error}</p>)}
            </Segment>
        </TransitionablePortal>
    )
}

export default observer(ErrorPortal);
