import {SubscriptionSummaryDto} from "../Entities/Dtos";
import api from "../Services/Api";
import {observable} from "mobx";

class AnalyticsStore {
    @observable awaitingPayments: SubscriptionSummaryDto[] = [];
    @observable visible: boolean = false;

    async show() {
        await this.loadAwaitingPayments();
        this.visible = true;
    }

    hide() {
        this.visible = false;
    }

    switch() {
        if (this.visible) {
            this.hide();
        } else {
            void this.show();
        }
    }

    async loadAwaitingPayments() {
        const result = await api.get('admin/awaiting-payments');
        if (result.success) {
            this.awaitingPayments = result.data;
        }
    }
}

const analyticsStore = new AnalyticsStore();
export default analyticsStore;