export enum OrderActionType {
    SetMarkings = "SetMarkings",
    SetDeliveryCost = "SetDeliveryCost",
    SetDeliveryTerms = "SetDeliveryTerms",
    SetTrackNumber = "SetTrackNumber",
    FixTrackNumber = "FixTrackNumber",
    Confirm = "Confirm",
    Reject = "Reject",
    Perform = "Perform",
    Receive = "Receive"
}
