import {Label, Menu} from "semantic-ui-react";
import React from "react";
import MessengerAccount from "../../Stores/Messenger/MessengerAccount";
import messengerStorage from "../../Stores/Messenger/MessengerStorage";
import {observer} from "mobx-react-lite";

function MessengerAccountListItem(props: { account: MessengerAccount }) {
    const {account} = props;
    return <>
        <Menu.Item
            active={messengerStorage.selectedAccount === account}
            onClick={() => messengerStorage.selectAccount(account)}
        >
            {account.user.name}
            {account.chatWithNotificationsCount !== 0 &&
                <Label color={'red'} size={'mini'} circular>{account.chatWithNotificationsCount}</Label>
            }
        </Menu.Item>
    </>
}

export default observer(MessengerAccountListItem);