import {InformationPanelItem} from "../Dashboard/InformationPanelItem";
import {List} from "semantic-ui-react";
import React from "react";
import {observer} from "mobx-react-lite";
import storage from "../../Stores/AvitoAccountsSettingsStorage";
import AvitoAccountRaw from "./AvitoAccountRaw";
import NewAvitoAccount from "./NewAvitoAccount";

function AvitoAccountList() {
    return <InformationPanelItem header={'Аккаунты авито'} listPlaceholder loading={storage.loading && !storage.isLoaded}>
        {storage.accounts.length > 0 && <List selection>
            {storage.accounts.map(account =>
                <AvitoAccountRaw key={account.id} account={account}/>)}
        </List>}
        <NewAvitoAccount/>
    </InformationPanelItem>;
}

export default observer(AvitoAccountList);
