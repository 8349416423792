import { observer } from 'mobx-react-lite';
import React from 'react';
import { Grid, Header, Icon, Input } from 'semantic-ui-react';
import Template from "../../../Entities/Template";

function TemplateName(props: {template: Template}) {
    const {template} = props;
    return (
        <>
            {!template.isEditStarted &&
                <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '10px' }}>
                    <Header as='h3' className='template-name-header'>
                        {template.name}
                        <Icon name='pencil' 
                              style={{ fontSize: '0.9em' }} 
                              className='template-name-edit-icon' 
                              onClick={() => template.isEditStarted = true}/>
                    </Header>
                    {
                        !template.isNew &&
                        <a style={{ paddingLeft: '10px' }} onClick={() => template.copy()}>Копировать</a>
                    }
                </div>
            }
            {template.isEditStarted &&
                <Grid>
                    <Grid.Column width={'5'}>
                        <Input
                            as={'h3'}
                            className='template-name-input'
                            fluid
                            autoFocus={true}
                            value={template.name}
                            onChange={(e) => template.name = e.target.value}
                            onBlur={() => template.saveName()}
                            onKeyDown={(e: any) => {
                                if (e.key === 'Enter') {
                                    void template.saveName();
                                }
                            }} />
                    </Grid.Column>
                </Grid>}
        </>
    );
}

export default observer(TemplateName);