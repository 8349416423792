import React from "react";
import FieldName from "../Shared/FieldName";
import FieldValue from "../Shared/FieldValue";
import EditorInput from "../Shared/EditorInput";
import {Button, Grid, Input} from "semantic-ui-react";
import DictionaryItem from "../../Stores/Dictioanaries/DictionaryItem";
import {observer} from "mobx-react-lite";

function DictionaryItemRaw(props: { item: DictionaryItem }) {
    const {item} = props;
    return <Grid.Row key={item.key}>
        <FieldName name={item.key}/>
        <FieldValue>
            {item.isEditing
                ?
                <EditorInput value={item.value}
                             onEdit={value => item.updateValue(value)}
                             cancel={() => item.cancelEditing()}
                             save={() => void item.save()}
                             canSave={item.canSave}
                             isSaving={item.isSaving}
                />
                :
                <Input onClick={() => item.startEditing()}
                       readOnly
                       label={<Button icon={'trash'}
                                      onClick={() => void item.delete()}
                                      loading={item.isDeleting}
                                      basic/>}
                       labelPosition={'right'}
                       value={item.value} fluid/>
            }
        </FieldValue>
    </Grid.Row>
}

export default observer(DictionaryItemRaw);