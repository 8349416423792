import { observer } from 'mobx-react-lite'
import React, {useEffect} from 'react'
import { Dropdown, Grid, Icon} from 'semantic-ui-react'
import productFolderListStore from "../../Stores/ProductFolderListStore";
import {InformationPanelItem} from "../Dashboard/InformationPanelItem";
import templateByFoldersStore from "../../Stores/TemplateByFoldersStore";
import templateListStore from "../../Stores/TemplateListStore";
import TemplateMappingItem from "./TemplateMappingItem";

function TemplateMappingBlock() {
    useEffect(() => {
        void productFolderListStore.load();
    }, []);
    return (
        <>
            <InformationPanelItem header='Связь групп товаров с шаблонами'>
                <Grid verticalAlign='middle'>
                    <Grid.Column width={9}>
                        <Dropdown fluid
                            selection
                            options={productFolderListStore.folders.filter(x => !x.templateName).map((template) => { return { text: template.name, value: template.id } })}
                            placeholder='Выберите группу'
                            search
                            value={templateByFoldersStore.folders}
                            onChange={(_, data) => templateByFoldersStore.folders = data.value ? (data.value as string[]) : []}
                            error={templateByFoldersStore.trySave && templateByFoldersStore.folders.length === 0}
                            multiple
                            noResultsMessage={'Нет групп'}
                            clearable
                        />
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <Dropdown fluid
                            selection
                            options={templateListStore.data.map((template) => { return { text: template.name, value: template.id } })}
                            placeholder='Выберите шаблон'
                            search
                            error={templateByFoldersStore.trySave && templateByFoldersStore.templateId === ''}
                            value={templateByFoldersStore.templateId}
                            onChange={(_, data) => templateByFoldersStore.templateId = data.value ? (data.value as string) : ''}
                            clearable
                        />
                    </Grid.Column>
                    <Grid.Column verticalAlign={'middle'} width={1}>
                        <Icon fitted name='save' color={templateByFoldersStore.canSave ? 'teal' : 'grey'} style={{ 'cursor': 'pointer', fontSize: '1.5em' }} onClick={templateByFoldersStore.save} />
                    </Grid.Column>
                </Grid>
                <Grid>
                    {productFolderListStore.folders.map((x) => x.templateName && <TemplateMappingItem item={x} key={x.id}/>)}
                </Grid>
            </InformationPanelItem>
        </>
    )
}

export default observer(TemplateMappingBlock);
