import {observable} from "mobx";
import MessengerAccount from "./MessengerAccount";
import api from "../../Services/Api";
import {MessengerAccountDto} from "../../Entities/Dtos";
import store from "../ProfileInfoStore";

class MessengerStorage {
    @observable accounts: MessengerAccount[] = [];
    @observable selectedAccount?: MessengerAccount;

    async load() {
        if (!store.isLoaded) {
            await store.load();
        }
        const result = await api.get("messenger/account.list")
        if (result.success) {
            const accountDtos = result.data as MessengerAccountDto[];
            this.accounts = accountDtos.map(x => new MessengerAccount(x.id, x.user));
            this.selectAccount(this.accounts[0]);
        }
    }

    selectAccount(account: MessengerAccount) {
        if (account === this.selectedAccount) return;
        this.selectedAccount = account;
        this.selectedAccount.selectedChat = undefined;
        void this.selectedAccount.loadChats();
    }
}

const messengerStorage = new MessengerStorage();
export default messengerStorage;