import {List} from "semantic-ui-react";
import React from "react";
import {observer} from "mobx-react-lite";
import AvitoAccountSettingsStorage from "../../Stores/AvitoAccountSettingsStorage";
import avitoAccountsSettingsStorage from "../../Stores/AvitoAccountsSettingsStorage";
import DeleteButton from "../Shared/DeleteButton";

function AvitoAccountRaw(props: { account: AvitoAccountSettingsStorage }) {
    const {account} = props;
    return <List.Item active={account.id === avitoAccountsSettingsStorage.current?.id}
                      onClick={() => avitoAccountsSettingsStorage.selectAccount(account)}>
        <DeleteButton onDelete={() => avitoAccountsSettingsStorage.startDelete(account)}/>
        {account.name}
    </List.Item>
}

export default observer(AvitoAccountRaw);
