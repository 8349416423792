import React from "react";
import {observer} from "mobx-react-lite";
import {Button, Form, FormField, Grid, Segment} from "semantic-ui-react";
import eventTesterStore from "./EventTesterStore";

function EventTester() {
    if (!eventTesterStore.visible) {
        return <></>
    }

    return <Grid>
        <Grid.Column width={'5'}>
                <Segment>
                    <Form>
                        <FormField>
                            <label>StockTransferEventId</label>
                            <input value={eventTesterStore.stockTransferEventId}
                                   onChange={e => eventTesterStore.setStockTransferEventId(e.target.value)}/>
                        </FormField>
                        <Button onClick={() => eventTesterStore.triggerStockTransferEventHandler()} color={'teal'}>Trigger</Button>
                        <Button floated={'right'} onClick={() => eventTesterStore.hide()}>Close</Button>
                    </Form>
                </Segment>
        </Grid.Column>
        <Grid.Column width={'5'}>
            <Segment>
                <Form>
                    <FormField>
                        <label>SkladEntityChangedEventId</label>
                        <input value={eventTesterStore.skladEntityChangedEventId}
                               onChange={e => eventTesterStore.setSkladEntityChangedEventId(e.target.value)}/>
                    </FormField>
                    <Button onClick={() => eventTesterStore.triggerSkladEntityChangedEventHandler()} color={'teal'}>Trigger</Button>
                    <Button floated={'right'} onClick={() => eventTesterStore.hide()}>Close</Button>
                </Form>
            </Segment>
        </Grid.Column>
    </Grid>
}

export default observer(EventTester);