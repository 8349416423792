class Localizator {
    getMessage(input: string): string{
        if (input === "AvitoWrongCredential") {
            return "Неверные данные авторизации Авито"
        }

        return input;
    }
}

const localizator = new Localizator();
export default localizator;
