import React from "react";
import {Table} from "semantic-ui-react";
import UserRow from "./UserRow";
import {UserDto} from "../../Entities/Dtos";

interface IUserTableProps {
    users: UserDto[];
}

function UserTable(props: IUserTableProps) {
    const {users} = props;
    return (
        <Table textAlign={'center'} style={{marginTop: '25px'}}>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>№</Table.HeaderCell>
                    <Table.HeaderCell>Id</Table.HeaderCell>
                    <Table.HeaderCell>Логин</Table.HeaderCell>
                    <Table.HeaderCell>Товары</Table.HeaderCell>
                    <Table.HeaderCell>Токен</Table.HeaderCell>
                    <Table.HeaderCell>Тариф</Table.HeaderCell>
                    <Table.HeaderCell>ExpiredOn</Table.HeaderCell>
                    <Table.HeaderCell/>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {users.map((user, i) => 
                    <UserRow number={i + 1} 
                             user={user}
                             key={user.id}/>)}
            </Table.Body>
        </Table>
    )
}

export default UserTable;
