import {observable, runInAction} from "mobx";
import api from "../../Services/Api";
import {AvitoUserDto, ChatInfoDto} from "../../Entities/Dtos";
import ChatStorage from "../../Stores/Messenger/ChatStorage";
import {mapToChatStorage} from "../../Stores/Messenger/MessengerAccount";
import websocketEventConsumer from "../../Services/WebsocketEventConsumer";

class OrderWidgetStore {
    @observable orderId?: string;
    @observable contextKey?: string;
    @observable isLoading: boolean = false;
    @observable error?: string;
    @observable chat?: ChatStorage;
    @observable avitoUser?: AvitoUserDto;

    public async init(contextKey: string, orderId: string) {
        this.contextKey = contextKey;
        this.setIsLoading(true);
        try {
            const authSuccess = await api.auth(contextKey);
            if (authSuccess) {
                this.orderId = orderId;
                await this.load();
            }
        } finally {
            this.setIsLoading(false);
        }
    }

    private async load() {
        if (!this.orderId) {
            return;
        }

        const result = await api.get('order/getChatInfo?uuid=' + this.orderId);
        if (result.success) {
            const chatInfo = result.data as ChatInfoDto;
            runInAction(()=> {
                this.chat = mapToChatStorage(chatInfo.chat);
                this.avitoUser = chatInfo.user;
                void this.chat.loadMessages();
                //void websocketEventConsumer.consumeToUserEvents(chatInfo.userId);
            })
        }
        else {
            this.setError(result.error);
        }
    }

    public cleanup() {
        this.orderId = undefined;
        this.contextKey = undefined;
        this.error = undefined;
    }

    private setIsLoading(isLoading: boolean) {
        this.isLoading = isLoading;
    }
    
    private setError(error: string) {
        this.error = error;
    }
}

const orderWidgetStore = new OrderWidgetStore();
export default orderWidgetStore;