import {HubConnection, HubConnectionBuilder} from "@microsoft/signalr";
import successStore from "../Stores/SuccessStore";
import profileInfoStore from "../Stores/ProfileInfoStore";
import skladStore from "../Stores/SkladStore";
import errorStore from "../Stores/ErrorStore";
import messengerStorage from "../Stores/Messenger/MessengerStorage";
import {AvitoChatDto, AvitoMessageDto} from "../Entities/Dtos";
import {mapToChatStorage} from "../Stores/Messenger/MessengerAccount";

class WebsocketEventConsumer {
    connection: HubConnection;
    alreadyConsumeToUserEvent: boolean = false;

    constructor() {
        this.connection = new HubConnectionBuilder()
            .withUrl('/notificator')
            .withAutomaticReconnect()
            .build();

        void this.connection.start();
    }
    
    stop() {
        try {
            void this.connection.stop();
        }
        catch {
            
        }
    }

    async consumeToUserEvents(userId: string) {
        if (this.alreadyConsumeToUserEvent) {
            return;
        }
        try {
            await this.connection.invoke("JoinGroup", userId);
            this.alreadyConsumeToUserEvent = true;

            this.connection.on('Complete', _ => {
                successStore.add("Выгрузка завершена!")
                void profileInfoStore.load();
                void skladStore.load();
            });

            this.connection.on("Count", count => {
                profileInfoStore.productCount = count;
            });

            this.connection.on("UpdateUserDataStatus", status => {
                if (status === "Start") {
                    skladStore.dataUpdateIsRunning = true;
                } else if (status === "Finish") {
                    successStore.add("Данные обновлены!")
                    void profileInfoStore.load();
                    void skladStore.load();
                } else if (status === "Error") {
                    errorStore.add("Ошибка обновления данных");
                    skladStore.dataUpdateIsRunning = false;
                }
            });

            this.connection.on("AvitoMessageReceived", (accountId, chatId, message: AvitoMessageDto) => {
                const selectedAccount = messengerStorage.selectedAccount;
                if (selectedAccount?.selectedChat?.id === chatId) {
                    const selectedChat = selectedAccount!.selectedChat!;
                    selectedChat.lastMessage = message;
                    selectedAccount!.sortChats();
                    if (!selectedChat.messages.some(x => x.id === message.id)) {
                        selectedChat.increaseNotificationCount();
                    }
                    void selectedAccount!.selectedChat!.loadMessages();
                } else if (selectedAccount?.id === accountId) {
                    const currentChat = selectedAccount?.chats.filter(x => x.id === chatId)[0];
                    if (currentChat) {
                        currentChat.lastMessage = message;
                        selectedAccount!.sortChats();
                        if (!currentChat.messages.some(x => x.id === message.id)) {
                            currentChat.increaseNotificationCount();
                        }
                    }
                } else {
                    const currentAccount = messengerStorage.accounts.filter(account => account.id === accountId)[0];
                    if (currentAccount) {
                        void currentAccount.loadChats();
                    }
                }
            });

            this.connection.on("AvitoChatCreated", (accountId, chat: AvitoChatDto) => {
                const currentAccount = messengerStorage.accounts.filter(account => account.id === accountId)[0];
                if (currentAccount) {
                    void currentAccount.chats.push(mapToChatStorage(chat));
                    currentAccount.sortChats();
                }
            });
            
            this.connection.on("AvitoInitCompleted", (accountId) => {
                const currentAccount = messengerStorage.accounts.filter(account => account.id === accountId)[0];
                if (currentAccount) {
                    currentAccount.loadChats();
                }
            });

            this.connection.on("AvitoChatReadEvent", (accountId, chat: AvitoChatDto) => {
                const currentAccount = messengerStorage.accounts.filter(account => account.id === accountId)[0];
                if (currentAccount) {
                    void currentAccount.softLoadChats();
                }
            });
        } catch {
            console.log("Error while ws connect")
        }
    }
}

const websocketEventConsumer = new WebsocketEventConsumer();
export default websocketEventConsumer;