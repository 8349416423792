import {Form, Grid, Input, TextArea} from "semantic-ui-react";
import React from "react";
import {observer} from "mobx-react-lite";
import FieldEditor from "./FieldEditor";
import FieldName from "./FieldName";
import FieldValue from "./FieldValue";
import IHasNameAndEditor from "../../Entities/IHasNameAndEditor";
import FieldValueEntity from "../../Entities/FieldValue";

function FieldValueRaw(props: { field: IHasNameAndEditor, fieldValue: FieldValueEntity, onSave: () => void }) {
    const {field,fieldValue, onSave} = props;
    return <Grid.Row>
        <FieldName>
            {field.editor.isEditing ? <FieldEditor store={field.editor}/> :
                <Input onClick={() => field.editor.startEditing()}
                       readOnly
                       value={field.name} fluid/>}
        </FieldName>
        <FieldValue>
            <Form>
                <TextArea rows={3}
                          value={fieldValue.value}
                          onKeyDown={(e: any) => {
                              if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
                                  onSave();
                              }
                          }}
                          onChange={(_, data) => {
                              fieldValue.changeValue(data.value as string)
                          }}
                />
            </Form>
        </FieldValue>
    </Grid.Row>;
}

export default observer(FieldValueRaw)
