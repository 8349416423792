import React, {useEffect} from "react";
import avitoAccountsSettingsStorage from "../../Stores/AvitoAccountsSettingsStorage";
import {Grid} from "semantic-ui-react";
import AvitoAccountList from "./AvitoAccountList";
import {observer} from "mobx-react-lite";
import AvitoAccountComponent from "./AvitoAccountComponent";
import ConfirmDelete from "../Shared/ConfirmDelete";

function AvitoAccountsSettingPage() {
    useEffect(() => {
        void avitoAccountsSettingsStorage.load()
    }, [])

    return <Grid>
        <ConfirmDelete isOpen={avitoAccountsSettingsStorage.deletingAccount !== undefined}
                       name={avitoAccountsSettingsStorage.deletingAccount?.name}
                       isLoading={avitoAccountsSettingsStorage.deletingAccount?.deleting}
                       onConfirm={() => void avitoAccountsSettingsStorage.applyDelete()}
                       onCancel={() => avitoAccountsSettingsStorage.cancelDelete()}
        />
        <Grid.Column width={3}>
            <AvitoAccountList/>
        </Grid.Column>
        <Grid.Column width={10}>
            {avitoAccountsSettingsStorage.current &&
                <AvitoAccountComponent account={avitoAccountsSettingsStorage.current}/>}
        </Grid.Column>
    </Grid>
}

export default observer(AvitoAccountsSettingPage);
