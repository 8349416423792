import {observer} from "mobx-react-lite";
import React from "react";
import {Button, List} from "semantic-ui-react";
import ChatListItem from "./ChatListItem";
import {InformationPanelItem} from "../Dashboard/InformationPanelItem";
import MessengerAccount from "../../Stores/Messenger/MessengerAccount";
import './Style.css';

function ChatList(props: { account: MessengerAccount }) {
    const {account} = props;

    return <InformationPanelItem listPlaceholder loading={account.loading} className={'chat-list'}>
        <List selection>
            {account.chats.length === 0 && !account.loading ? 'Нет чатов' : account.chats.map(x => <ChatListItem
                chat={x}
                key={x.id}
                isActive={x === account.selectedChat}
                onSelect={chat => account.selectChat(chat)}/>)}
        </List>
        {account.hasMorePage &&
            <Button onClick={() => account.softLoadChats(true)}
                    loading={account.loading || account.loadingMore}
                    fluid
                    color={'teal'}
                    basic>Загрузить еще</Button>
        }
    </InformationPanelItem>
}

export default observer(ChatList);
