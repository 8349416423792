import {Confirm} from "semantic-ui-react";
import React from "react";

function ConfirmDelete(props: {
    isOpen: boolean,
    onCancel?: () => void,
    onConfirm?: () => void,
    isLoading?: boolean,
    name?: string
}) {
    const {isOpen, onCancel, onConfirm, isLoading, name} = props;
    return <Confirm
        open={isOpen}
        dimmer={'blurring'}
        onCancel={onCancel}
        onConfirm={onConfirm}
        confirmButton={{
            loading: isLoading,
            disabled: isLoading,
            content: 'Да'
        }}
        cancelButton={{
            content: 'Отмена'
        }}
        centered={false}
        content={'Уверены, что хотите удалить ' + name + '?'}
    />
}

export default ConfirmDelete;
