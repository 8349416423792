import React, {useEffect} from "react";
import FieldWithFieldIdSelector from "../Shared/FieldWithFieldIdSelector";
import store from "../../Stores/ProductStockCheckerStore";
import TextField from "../Shared/TextField";
import {Button, Grid} from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import {InformationPanelItem} from "../Dashboard/InformationPanelItem";
import authStore from "../../Stores/AuthStore";

function ProductStockChecker(props: { avitoAccountId: string }) {
    useEffect(() => {
        return function cleanup() {
            store.cleanup();
        }
    }, []);
    return <InformationPanelItem visible={store.visible && authStore.isAdmin} header={'Проверка остатка'}
                                 shadows={false}>
        <Grid>
            <TextField name={'Id товара'}
                       value={store.productId ?? ''}
                       onChange={value => store.changeProductId(value)}
            />
            <FieldWithFieldIdSelector fields={store.productTypes}
                                      onChange={value => store.changeProductType(value)}
                                      name={'Тип продукта'}
                                      value={store.productType}
            />
            {store.stock && <Grid.Row>
                <Grid.Column textAlign={'center'}>
                    Остаток: {store.stock}
                </Grid.Column>
            </Grid.Row>}
            <Grid.Row>
                <Grid.Column>
                    <Button basic onClick={() => store.hide()}>Скрыть</Button>
                    <Button loading={store.isLoading}
                            disabled={!store.canCheck}
                            floated={'right'} 
                            basic
                            onClick={() => store.check(props.avitoAccountId)}>Проверить</Button>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </InformationPanelItem>
}

export default observer(ProductStockChecker);