import {observable} from "mobx";
import api from "../../Services/Api";
import {DictionaryWithItemsDto} from "../../Entities/Dtos";
import DictionaryItem from "./DictionaryItem";
import {Dictionary} from "./Dictionary";

class DictionaryStore {
    @observable id?: string;
    @observable dictionary?: Dictionary;
    @observable isLoading: boolean = false;
    @observable creationDictionaryItem?: DictionaryItem;
    @observable isLoaded: boolean = false;
    @observable hasItems: boolean = false;

    async init(id: string) {
        this.id = id;
        await this.load();
    }

    cleanup() {
        this.id = undefined;
        this.dictionary = undefined;
        this.creationDictionaryItem = undefined;
        this.hasItems = false;
        this.setIsLoading(false);
        this.setIsLoaded(false);
    }

    private async load() {
        this.setIsLoading(true);
        try {
            const result = await api.get(`dictionary/${this.id}`);
            if (result.success) {
                const dto = result.data as DictionaryWithItemsDto;
                const items = dto.items.map(item =>
                    new DictionaryItem(item.key, item.value,
                        () => {
                            void this.load();
                        }, this.id));
                this.dictionary = new Dictionary(dto.id, dto.name, items);
                this.hasItems = items.length > 0;
                if (!this.hasItems) {
                    this.startCreationDictionaryItem();
                }
                this.setIsLoaded(true);
            }
        } finally {
            this.setIsLoading(false);
        }
    }

    startCreationDictionaryItem() {
        this.creationDictionaryItem = new DictionaryItem('', '', () => {
            this.stopCreationDictionaryItem();
            void this.load();
        }, this.id);
    }

    stopCreationDictionaryItem() {
        this.creationDictionaryItem = undefined;
    }

    private setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    private setIsLoaded(value: boolean) {
        this.isLoaded = value;
    }
}

const dictionaryStore = new DictionaryStore();
export default dictionaryStore;