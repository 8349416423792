import {List} from "semantic-ui-react";
import React from "react";
import storage from "../../Stores/StoresSettingsStorage";
import {InformationPanelItem} from "../Dashboard/InformationPanelItem";
import {observer} from "mobx-react-lite";
import StoreRaw from "./StoreRaw";

function StoresList() {
    return <InformationPanelItem header={'Склады'} listPlaceholder loading={storage.loading && !storage.isLoaded}>
        <List selection>
            {storage.stores.map(store => <StoreRaw key={store.id} store={store}/>)}
        </List>
    </InformationPanelItem>;
}

export default observer(StoresList);
