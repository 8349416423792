import {observable, runInAction} from "mobx";
import ChatStorage from "./ChatStorage";
import api from "../../Services/Api";
import {AvitoChatDto, AvitoUserDto} from "../../Entities/Dtos";

export function mapToChatStorage(x: AvitoChatDto) {
    return new ChatStorage(x.id, x.createdOn, x.updatedOn, x.lastMessage, x.ad, x.client, x.notificationCount)
}

export default class MessengerAccount {
    @observable chats: ChatStorage[] = [];
    id: string;
    user: AvitoUserDto;
    @observable loading: boolean = false;
    @observable isLoaded: boolean = false;
    @observable selectedChat?: ChatStorage;
    @observable lastPageSize: number = 0;
    @observable skip: number = 0;
    @observable loadingMore: boolean = false;
    private pageSize: number = 20;

    get hasMorePage() {
        return this.lastPageSize === this.pageSize;
    }

    constructor(id: string, user: AvitoUserDto) {
        this.id = id;
        this.user = user;
    }

    async loadChats() {
        this.skip = 0;
        this.chats = [];
        this.changeLoading(true);
        await this.softLoadChats();
        this.changeLoading(false);
    }

    async softLoadChats(loadMore?: boolean) {
        if (loadMore && !this.hasMorePage) {
            return;
        }
        
        if (loadMore) {
            this.loadingMore = true;
        }
        
        const result = await api.get("messenger/chat.list?accountId=" + this.id + "&skip=" + (loadMore ? this.skip : 0) + "&take=" + this.pageSize)
        if (result.success) {
            runInAction(()=>{
                const chatDtos = result.data as AvitoChatDto[];
                this.lastPageSize = chatDtos.length;
                this.skip = this.skip + this.lastPageSize;
                const chats = chatDtos.map(x => mapToChatStorage(x));
                if (loadMore) {
                    chats.forEach(x => this.chats.push(x));
                } else {
                    this.chats = chats;
                }

                if (this.selectedChat) {
                    const filteredChat = this.chats.filter(x => x.id === this.selectedChat!.id)[0];
                    filteredChat.messages = this.selectedChat.messages;
                    this.selectedChat = filteredChat;
                }
            })
        }

        if (loadMore) {
            this.loadingMore = false;
        }
    }

    sortChats() {
        this.chats = this.chats.sort(function (a, b) {
            const aDate = a.lastMessage.createdOn;
            const bDate = b.lastMessage.createdOn;
            if (aDate > bDate) return -1;
            if (aDate < bDate) return 1;
            return 0;
        });
    }

    selectChat(chat: ChatStorage) {
        if (chat === this.selectedChat) return;
        this.selectedChat = chat;
        void this.selectedChat.loadMessages();
    }

    private changeLoading(value: boolean) {
        this.loading = value;
    }

    get chatWithNotificationsCount() {
        return this.chats.filter(x => x.notificationCount != 0).length;
    }
}