import React from "react";
import {observer} from "mobx-react-lite";
import tokenConverterStore from "./TokenConverterStore";
import {Button, Form, FormField, Grid, Segment} from "semantic-ui-react";

function TokenConverter() {
    if (!tokenConverterStore.visible) {
        return <></>
    }

    return <Grid>
        <Grid.Column width={'7'}>
            <Segment>
                <Form>
                    <FormField>
                        <label>Input</label>
                        <input value={tokenConverterStore.inputValue}
                               onChange={e => tokenConverterStore.setInputValue(e.target.value)}/>
                    </FormField>
                    <FormField>
                        <label>Output</label>
                        <input value={tokenConverterStore.outputValue}/>
                    </FormField>
                    <Button onClick={() => tokenConverterStore.encode()} color={'teal'}>Encode</Button>
                    <Button onClick={() => tokenConverterStore.decode()} color={'blue'}>Decode</Button>
                    <Button floated={'right'} onClick={() => tokenConverterStore.hide()}>Close</Button>
                </Form>
            </Segment>
        </Grid.Column>
    </Grid>
}

export default observer(TokenConverter);